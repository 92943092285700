import * as moment from "moment";
import {Moment} from "moment";
import * as $ from "jquery";
import { MachineView } from "../MachineView";

/**
 * This class represents a wrapper over machine view sub elements to keep them up-to-date.
 * The view is updated after incoming data from the server or periodically via this class.
 */
export class FreshElement {

    private machineView: MachineView;
    private element: Function;
    private lastUpdated: Moment;
    private maxDecay: number;

    /**
     * 
     * @param element A callback function returning html string with up-to-date content. This element must have a unique id!
     * @param maxDecay How old the element is allowed to be, in ms.
     */
    constructor(machineView: MachineView, element: Function, maxDecay: number) {
        this.machineView = machineView;
        this.element = element;
        this.lastUpdated = moment();
        this.maxDecay = maxDecay;
    }

    /**
     * Returns true if this element is not older than decay.
     */
    get isFresh(): boolean {
        const now = moment();
        const nowMinusDecay = now.subtract(this.maxDecay, 'ms');
        return this.lastUpdated.isAfter(nowMinusDecay);
    }

    /**
     * Updates the element with fresh content if it is not fresh.
     */
    updateIfNeeded() {
        if (this.machineView.isContentRendered && !this.isFresh) {
            this.update();
        }
    }

    /**
     * Updates the element with fresh content.
     */
    update() {
        const newHtml = $(this.element());
        const id = newHtml.attr('id');
        const machineElement = this.machineView.rootElement;

        machineElement.find(`#${id}`).replaceWith(newHtml);
        this.setLastUpdatedNow();
    }

    /**
     * Sets the time of the last update to now.
     */
    setLastUpdatedNow() {
        this.lastUpdated = moment();
    }
}