import { singleton } from "tsyringe";

@singleton()
export class LoadingIndicator {
    private element: HTMLElement;

    constructor() {
        this.element = document.getElementById('loading');
    }

    show() {
        this.element.style.display = 'block';
    }

    hide() {
        this.element.style.display = 'none';
    }
}