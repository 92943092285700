import "reflect-metadata";
import { container } from "tsyringe";
import { ARController } from "./ARController";
import { URLParams } from "./services/URLParams";
import {Preloader} from "./services/Preloader";
import { ControlPanel } from "./ui/ControlPanel";
import { LoadingIndicator } from "./ui/LoadingIndicator";
import { StatusView } from "./ui/StatusView";
import { CSS } from './services/CSS';

export class Application {

    private loadingIndicator = container.resolve(LoadingIndicator);
    private preloader = container.resolve(Preloader);
    private controlPanel = container.resolve(ControlPanel);
    private ar = container.resolve(ARController);
    private status = container.resolve(StatusView);
    private css = container.resolve(CSS);

    init() {
        this.css.attachGlobalStyles();
        this.status.attach();
        this.controlPanel.attach();

        const loadingSequence = this.preloader.loadAll(URLParams.IsGoogleGlass);

        this.ar.init();
        this.configureInitialState();
    
        // wait while everything is being loaded, then start the app
        loadingSequence.then(() => this.onLoad());
    }

    private configureInitialState() {
        if (!URLParams.EnableSync) {
            this.status.show('SYNC DISABLED');
        }
    
        if (URLParams.Language) {
            this.ar.findAndSetLanguage(URLParams.Language);
        }

        this.ar.changeViewType(URLParams.ViewType);

        if (URLParams.PermanentMachineView !== null) {
            if (URLParams.EnableSync) {
                alert("Permanent view mode cannot be enabled when sync is enabled!");
            } else {
                this.ar.setPermanentMachineView(URLParams.PermanentMachineView);
            }
        }
    }

    private onLoad() {
        if (!URLParams.HideMenu) {
            this.controlPanel.show();
        }

        this.loadingIndicator.hide();
        this.ar.showPermanentViewIfRequired();
        
        // start sync after a while to give a-frame a little bit more time to render images before changing them
        setTimeout(() => this.ar.startSync(), 3000); 
    }

}