import { GoogleGlassScene } from "./GoogleGlassScene";
import { ARScene } from "./ARScene";

class SceneFactory {
    /**
     * Create Scene Factory
     * @param {boolean} isGoogleGlass 
     * @param {string} arJsConfig string 
     * @returns {ARScene | GoogleGlassScene}
     */
    create(isGoogleGlass: boolean, arJsConfig: string): GoogleGlassScene | ARScene {
        if (isGoogleGlass) {
            return new GoogleGlassScene(arJsConfig);
        } else {
            return new ARScene(arJsConfig);
        }
    }
}

export const sceneFactory = new SceneFactory();