export class WorkerPersonDto {
    /**
     * ID of Worker
     */
    id: string;

    /**
     * Name of Worker
     */
    name: string;

    /**
     * Profile Picture URL
     */
    photoUrl?: string;

}