import { singleton } from "tsyringe";
import { globalStyles } from "../style";
import * as $ from 'jquery';

@singleton()
export class CSS {

    private styles = new Array<string>();

    get attachedStyles(): string {
        return this.styles.join("\n\n");
    }

    attachGlobalStyles() {
        this.attach(globalStyles);
    }

    attach(css: string) {
        const html = `<style>${css}</style>`;
        document.body.appendChild($(html)[0]);
        this.styles.push(css);
    }
}