import { container } from "tsyringe";
import { CSS } from "../services/CSS";
import { Scene } from "./Scene";

export class GoogleGlassScene extends Scene {
    
    private css = container.resolve(CSS);

    attach() {
        this.css.attach(this.getCSS());
        super.attach();
    }

    protected getHtmlString(): string {
        return `
        <div>
            <div id="html-scene"></div>
            <a-scene embedded arjs="${this.arJsConfig}" id="scene"></a-scene>
        </div>
        `;
    }

    private getCSS(): string {
        return `
        
        `;
    }

}