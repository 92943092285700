import {WorkerPerson} from "../model/WorkerPerson";
import {Machine} from "../model/Machine";
import {
    AllDataMessage,
    MachineEfficiencyMessage,
    MachineProjectModelUrlMessage,
    MachineProjectsMessage,
    MachineStateMessage,
    MachineWorkerMessage,
    IMessage,
    MachineProjectPiecesMessage,
    MachineMarkerLevelMessage
} from "../sync/Message";
import {MessageType} from "../sync/MessageType";
import {Project} from "../model/Project";
import {MachineStateHistory} from "../model/MachineStateHistory";
import {ProjectDto} from "../model/ProjectDto";
import {MachineDto} from "../model/MachineDto";
import {MachineStateHistoryDto} from "../model/MachineStateHistoryDto";
import { MachineSimpleDto } from "../model/MachineSimpleDto";

export class Data {
    machines = new Array<Machine>();

    findMachine(machineId: number): Machine {
        return this.machines.find(machine => machine.machineId === machineId);
    }

    asDto(): Array<MachineDto> {
        return this.machines.map(m => m.asDto());
    }

    asSimpleDto(): Array<MachineSimpleDto> {
        return this.machines.map(m => m.asSimpleDto());
    }

    asMessage(): AllDataMessage {
        return new AllDataMessage(this.asDto());
    }

    update(message: IMessage) {
        switch (message.type) {
            case MessageType.allData:
                const machinesMessage = <AllDataMessage>message;
                this.updateMachines(machinesMessage.machines);
                this.prune();
                break;

            case MessageType.machineWorker:
                const workerMessage = <MachineWorkerMessage>message;
                this.updateMachineWorker(workerMessage.machineId, workerMessage.worker);
                if (workerMessage.worker === null || workerMessage.worker.id == "-1") {
                    this.updateMachineEfficiency(workerMessage.machineId, 0);
                }
                break;

            case MessageType.machineEfficiency:
                const efficiencyMessage = <MachineEfficiencyMessage>message;
                this.updateMachineEfficiency(efficiencyMessage.machineId, efficiencyMessage.efficiency);
                break;

            case MessageType.machineProjects:
                const projectsMessage = <MachineProjectsMessage>message;
                this.updateMachineProjects(projectsMessage.machineId, projectsMessage.projects);
                break;

            case MessageType.machineState:
                const stateMessage = <MachineStateMessage>message;
                this.addMachineStates(stateMessage.machineId, stateMessage.stateHistory);
                break;

            case MessageType.machineProjectModelUrl:
                const modelUrlMessage = <MachineProjectModelUrlMessage>message;
                this.updateModelUrl(modelUrlMessage.machineId, modelUrlMessage.projectName, modelUrlMessage.modelUrl);
                break;

            case MessageType.machineProjectPieces:
                const updatePiecesMessage = <MachineProjectPiecesMessage>message;
                this.addMachineProjectPieces(updatePiecesMessage.machineId, updatePiecesMessage.projectName, updatePiecesMessage.pieces, updatePiecesMessage.rejects);
                break;

            case MessageType.machineMarkerLevel:
                const markerLevelMessage = <MachineMarkerLevelMessage>message;
                this.updateMarkerLevel(markerLevelMessage.machineId, markerLevelMessage.markerLevel);
                break;
                            
            default:
                console.log("[Data] Unsupported message type.");
        }
    }

    updateMachines(machines: Array<MachineDto>) {
        this.machines = machines.map(m => Machine.fromDto(m));
    }

    updateMachineWorker(machineId: number, worker: WorkerPerson) {
        const machine = this.findMachine(machineId);
        if (machine !== undefined) {
            machine.worker = WorkerPerson.fromDto(worker);
        }
    }

    addMachineStates(machineId: number, states: MachineStateHistoryDto) {
        const machine = this.findMachine(machineId);
        if (machine !== undefined) {
            const newChanges = MachineStateHistory.fromDto(states);
            newChanges.forEach(change =>
                machine.stateHistory.push(change)
            );
        }
    }

    updateMachineProjects(machineId: number, projects: Array<ProjectDto>) {
        const machine = this.findMachine(machineId);
        if (machine !== undefined) {
            machine.projects = projects.map(project =>
                Project.fromDto(project)
            );
        }
    }

    updateMachineEfficiency(machineId: number, efficiency: number) {
        const machine = this.findMachine(machineId);
        if (machine !== undefined) {
            machine.efficiency = efficiency;
        }
    }

    updateModelUrl(machineId: number, projectName: string, modelUrl: string) {
        const machine = this.findMachine(machineId);
        if (machine !== undefined) {
            const project = machine.projects.find(p => p.name === projectName);
            if (project !== undefined) {
                project.modelUrl = modelUrl;
            }
        }
    }

    addMachineProjectPieces(machineId: number, projectName: string, pieces: number, rejects: number) {
        const machine = this.findMachine(machineId);
        if (machine !== undefined) {
            const project = machine.projects.find(p => p.name === projectName);
            if (project !== undefined) {
                project.pieces += pieces;
                project.rejects += rejects;
            }
        }
    }

    updateMarkerLevel(machineId: number, markerLevel: number) {
        const machine = this.findMachine(machineId);
        if (machine !== undefined) {
            machine.markerLevel = markerLevel;
        }
    }

    /**
     * Deletes obsolete data like old machine state changes.
     */
    prune() {
        this.machines.forEach(machine => {
            machine.stateHistory.pruneOldItems();
        });
    }
}