const whiteButton = require("./assets/white-button.svg");
const blueButton = require("./assets/blue-button.svg");
const filledButton = require("./assets/filled-button.svg");
const plusButton = require("./assets/plus.svg");
const minusButton = require("./assets/minus.svg");

export const globalStyles = `

@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("/fonts/Roboto_Condensed/RobotoCondensed-Bold.woff");
}

body {
    overflow: hidden;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    user-select: none;
    margin: 0;
}

.button {
    display: inline-flex;
    cursor: pointer;
    background-color: transparent;
    background-image: url('data:image/svg+xml;utf8,${whiteButton}');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 1.5rem 0.5rem;
    font-weight: bold;
    font-size: 2.5vw;
    color: white;
    width: 19vw;
    height: 7vw;
    text-align: center;
    margin-right: 1.5vw;
    justify-content: center;
    align-items: center;
}

.button.active {
    background-image: url('data:image/svg+xml;utf8,${blueButton}');
    color: #00FFF0;
}

input {
    font-weight: bold;
    padding-top: 7px;
    padding-bottom: 7px;
}

video {
    display: none;
}

[aframe-injected] {
    display: none;
}

#scene {
    width: 100%;
    height: 100%;
}

.height-input {
    margin-top: 7%;
    display: flex;
    align-items: center;
}

.button.increase-height {
    background-image: url('data:image/svg+xml;utf8,${plusButton}');
}

.button.decrease-height {
    background-image: url('data:image/svg+xml;utf8,${minusButton}');
}

.height-input > span {
    font-size: 3vw;
    font-weight: bold;
    padding-right: 2vw;
}

.interface-layout {
    position: absolute;
    left: 25%;
    top: 39%;
    z-index: 9;
    width: 50%;
}

.interface-language {
    position: absolute;
    top: 10%;
    z-index: 9;
    left: 25%;
    width: 48%;
}

#arjsDebugUIContainer {
    display: none;
}

#control-panel {
    position: absolute;
    left: 6%;
    top: 8%;
    width: 88%;
    height: 84%;
    background-color: rgba(0, 0, 0, 0.69);
    z-index: 9;
    display: none;
    text-align: center;
}

#control-panel h2 {
    color: white;
    margin-bottom: 0.5vw;
    font-size: 2vw;
    font-weight: bold;
}

.start {
    position: absolute;
    top: 70%;
    left: 25%;
    width: 50%;
    z-index: 9;
    text-align: right;
    background-image: url('data:image/svg+xml;utf8,${filledButton}');
    background-size: contain;
    background-repeat: no-repeat;
    color: black;
}

.button svg {
    width: 3vw;
    height: 3vw;
    margin-right: 5%;
    display: inline-block;
    fill: white;
}

.button.active svg {
    fill: #00FFF0;
}

#debug {
    display: none;
    color: pink;
    font-size: 3vw;
    font-weight: bold;
    position: fixed;
    top: 1vh;
    right: 3vw;
}


/* --- MACHINE VIEW 2D --- */

.machine {
    position: absolute;
    top: 30px;
    left: 10px;
    width: 100%;
    height: 100%;
}

.machine-state img {
    width: 60px;
}

.production-graph {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.machine-state-text,
.worker-profile,
.worker-profile-text,
.remaining-time-text,
.efficiency-text,
.production-graph,
.production-graph-text,
.produced-goods
{
    color: white;
}

.worker-profile img {
    width: 60px;
}

progress {
    max-width: 90%;
}

progress[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
    appearance: none;
    border-radius: 2px;
    border: 1px solid white;
}

progress[value]::-webkit-progress-bar {
    background-color: gray;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
    background-color: white;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

`;