import {Asset} from "./Asset";

export class AssetRegisterItem {

    readonly asset: Asset;
    readonly machineId: number;

    public constructor(asset: Asset, machineId: number) {
        this.asset = asset;
        this.machineId = machineId;
    }
    
}