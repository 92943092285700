import { AframeComponent } from "./AframeComponent";

export const MarkersComponent = new AframeComponent("markers", {
    init: function () {
        this.camera = document.querySelector('#camera').object3D;
        this.isEnabled = true;
    },

    markerFound: function () {
        this.isEnabled = false;
    },

    markerLost: function () {
        this.isEnabled = true;
    },

    tock: function () {
        //if (this.isEnabled) {
            // this.el.object3D.position.set(
            //     this.camera.position.x,
            //     this.camera.position.y,
            //     this.camera.position.z
            // );
        //}

        // this.el.object3D.rotation.set(
        //     this.camera.rotation.x,
        //     this.camera.rotation.y,
        //     this.camera.rotation.z,
        //     this.camera.rotation.order
        // );
    }
});
