import {dateFromMediaEfektAPIString} from "../utils";
import {ProjectDto} from "./ProjectDto";

export class Project {
    /**
     * Project Name in format "Product Number_Order Number"
     */
    readonly name: string;

    /**
     * Product Number as a part of the Project Name
     */
    readonly productNumber: string;

    /**
     * Order Number as a part of the Project Name
     */
    readonly orderNumber: string;

    /**
     * Planned Start of Project
     */
    plannedStart: Date;

    /**
     * Planned End of Project
     */
    plannedEnd: Date;

    /**
     * Is this project current?
     */
    isCurrent = false;

    /**
     * Model URL
     */
    modelUrl? = "";

    /**
     * Number of produced pieces (incl. rejects)
     */
    pieces: number;

    /**
     * Number of produced pieces that have a defect
     */
    rejects: number;

    /**
     * Total number of pieces to be produced.
     */
    plannedPieces: number;


    /**
     * Project
     * @constructor
     */
    constructor(name: string, plannedStart: Date, plannedEnd: Date, isCurrent: boolean, pieces: number, rejects: number, plannedPieces: number, modelUrl?: string) {
        this.name = name;
        this.plannedStart = plannedStart;
        this.plannedEnd = plannedEnd;
        this.isCurrent = isCurrent;
        this.modelUrl = modelUrl;
        this.pieces = pieces;
        this.rejects = rejects;
        this.plannedPieces = plannedPieces;

        const nameParts = name.split("_");
        this.productNumber = nameParts[0];
        if (nameParts.length >= 2) {
            this.orderNumber = nameParts[1];
        }
    }

    static fromDto(dto: ProjectDto) {
        return new Project(
            dto.name,
            dateFromMediaEfektAPIString(dto.plannedStart),
            dateFromMediaEfektAPIString(dto.plannedEnd),
            dto.isCurrent,
            dto.pieces,
            dto.rejects,
            dto.plannedPieces,
            dto.modelUrl,
        );
    }

    asDto() {
        let dto = new ProjectDto();
        dto.name = this.name;
        dto.plannedStart = this.plannedStart.toISOString();
        dto.plannedEnd = this.plannedEnd.toISOString();
        dto.isCurrent = this.isCurrent;
        dto.pieces = this.pieces;
        dto.rejects = this.rejects;
        dto.modelUrl = this.modelUrl;
        dto.plannedPieces = this.plannedPieces;
        return dto;
    }

    private calculateElapsedAndTotalTimes(): Array<number> {
        const start = this.plannedStart.getTime();
        const end = this.plannedEnd.getTime();
        const now = (new Date()).getTime();

        const total = Math.max(0, end - start);
        const elapsed = Math.min(total, Math.max(0, now - start));

        return [elapsed, total];
    }

    /**
     * Returns remaining time to the planned end of the project, in milliseconds.
     */
    get remainingTime(): number {
        const [elapsed, total] = this.calculateElapsedAndTotalTimes();
        return (total - elapsed); // in ms
    }

    /**
     * Returns how much of the planned project period has already passed, in percents.
     */
    get elapsedTimePercentage(): number {
        const [elapsed, total] = this.calculateElapsedAndTotalTimes();
        return elapsed / total;
    }
}
