import {Moment} from "moment";
import {ShiftConfig} from "./ShiftConfig";

export class Shift {
    date: Moment;
    label: string;
    start: Moment;
    end: Moment;
    lengthInHours: number;

    get formattedStart(): string {
        return this.start.format("HH:mm");
    }

    constructor(date: Moment, config: ShiftConfig) {
        this.date = date;
        this.label = config.label;
        this.start = this.date
            .clone()
            .set({
                hours: config.start.hour,
                minutes: config.start.minute,
                seconds: 0
            });
        this.end = this.start
            .clone()
            .add(config.lengthInHours, 'hours');
        this.lengthInHours = config.lengthInHours;
    }
}