import { AframeComponent } from "./AframeComponent";
import {Vector3, Box3} from "three";

/// Computes 3D object's bounding box a scales it so it fills the whole size given as the parameter.
/// Modifies the model position to be centered according to its bounding box.
/// Based on: https://stackoverflow.com/a/49387048/1073393
export const ModelNormalizedSize = new AframeComponent("normalized-size", {
    schema: {
        type: 'string',
        default: 1
    },
    init: function () {
        this.scale();
        this.el.addEventListener('object3dset', () => this.scale());
    },
    targetSize: function(): Vector3 {
        const [x, y, z] = this.data.split(" ");
        return new Vector3(parseFloat(x), parseFloat(y), parseFloat(z));
    },
    scale: function () {
        // Get the THREE object from A-FRAME HTML element
        const mesh = this.el.getObject3D('mesh');
        if (!mesh) return;

        // Retrieve the object global position and global scale (inherited from parent)
        const worldScale = new Vector3();
        mesh.getWorldScale(worldScale);
        const worldPos = new Vector3();
        mesh.getWorldPosition(worldPos);

        // Compute the object bounds (in global coordinates and scale)
        const bbox = new Box3().setFromObject(mesh);

        // Get the object size based on its bounding box
        let bboxSize = new Vector3();
        bbox.getSize(bboxSize);
        bboxSize.divide(worldScale); // convert size to local measure (relative to parent not world)

        // Get the object center based on its bounding box
        const center = new Vector3();
        bbox.getCenter(center);
        center.sub(worldPos); // convert global bbox coordinates to local offset (relative to parent not world)
        center.divide(worldScale); // correct local position by world scaling

        // Straighten lying models
        if (bboxSize.x > 3 * bboxSize.y && bboxSize.z > 3 * bboxSize.y) {
            mesh.rotateX(Math.PI / 2);
            bboxSize = new Vector3(bboxSize.x, bboxSize.z, bboxSize.y);
        }

        // Scale the object to a normalized size
        const scale = this.computeScale(bboxSize, this.targetSize());
        mesh.scale.set(scale, scale, scale); // scale the object
        center.multiplyScalar(scale); // correct the position of the object's bounding box

        // Recenter the object based on the center of bounding box
        mesh.position.sub(center);

        // // A visual bounding box for debugging
        // const box = document.createElement('a-box');
        // box.setAttribute('width', bboxSize.x * scale);
        // box.setAttribute('height', bboxSize.y * scale);
        // box.setAttribute('depth', bboxSize.z * scale);
        // box.setAttribute('color', 'pink');
        // box.setAttribute('wireframe', 'true');
        // this.el.parentElement.appendChild(box);
    },
    computeScale(bboxSize: Vector3, targetSize: Vector3): number {
        // find the longest side
        let longestSide: keyof Vector3= 'x';
        if (bboxSize.y > bboxSize.x && bboxSize.y > bboxSize.z) longestSide = 'y';
        if (bboxSize.z > bboxSize.x && bboxSize.z > bboxSize.y) longestSide = 'z';

        // basic scaling by fitting the longest side into the available space
        let scale = <number>targetSize[longestSide] / <number>bboxSize[longestSide];

        // ensuring all dimensions are in the available space
        if (bboxSize.x * scale > targetSize.x) {
            scale *= targetSize.x / (bboxSize.x * scale);
        }
        if (bboxSize.y * scale > targetSize.y) {
            scale *= targetSize.y / (bboxSize.y * scale);
        }
        if (bboxSize.z * scale > targetSize.z) {
            scale *= targetSize.z / (bboxSize.z * scale);
        }

        return scale;
    }
});