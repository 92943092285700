import {Moment} from "moment";
import {MachineState} from "../../../../common/model/MachineState";
import {container} from "tsyringe";
import {Languages} from "../../../languages/Languages";
import {Machine} from "../../../../common/model/Machine";
import {config} from "../../../../config";
import * as moment from "moment";
import {Shift} from "../../../../common/model/Shift";

export class ProductionGraphData extends Array<ProductionGraphDataForOneDay> {
    /**
     * Production Graph Data
     * @param {Machine} machine 
     * @param {number} numberOfDays 
     */
    constructor(machine: Machine, numberOfDays: number) {
        super();
        for(let dayOffset = 0; dayOffset < numberOfDays; dayOffset++) {
            const date = moment()
                .subtract(dayOffset, 'days')
                .startOf('day');
            this.push(new ProductionGraphDataForOneDay(machine, date));
        }
    }
}

export class ProductionGraphDataForOneDay {
    /**
     * A date to which this data belongs to.
     */
    date: Moment;

    /**
     * Formatted date to which this data belongs to.
     */
    formattedDate: string;

    /**
     * Including all machine states during the day divided into shifts.
     */
    shifts = Array<ProductionGraphDataShift>();

    /**
     * Production Graph Data For One Day
     * @param {Machine} machine 
     * @param {Moment} date 
     */
    constructor(machine: Machine, date: Moment) {
        const languages = container.resolve(Languages);
        this.date = date;
        this.formattedDate = date
            .locale(languages.currentLanguage.locale)
            .format("l");
        this.shifts = this.getMachineStatesSeparatedInShifts(machine);
    }

    /**
     * Converts state changes into state periods divided into shifts.
     * @param machine
     * @returns {Array<ProductionGraphDataShift>}
     */
    private getMachineStatesSeparatedInShifts(machine: Machine): Array<ProductionGraphDataShift> {
        return config.shifts.map(shiftConfig => {
            const shift = new Shift(this.date, shiftConfig);
            const stateChanges = machine.getStateChangesDuringShift(shift);

            // convert past state changes into periods with constant state
            let previousChange = stateChanges.shift();
            let states = stateChanges.map(change => {
                const length = change.time.diff(previousChange.time, 'hours', true);
                const state = new ProductionGraphDataState(previousChange.state, length);
                previousChange = change;
                return state;
            });

            // add a special empty period at the end to fill the empty space
            const start = previousChange !== undefined ? previousChange.time : shift.start;
            const length = shift.end.diff(start, 'hours', true);
            states.push(new ProductionGraphDataState(null, length));

            return new ProductionGraphDataShift(shift.label, shift.formattedStart, shift.lengthInHours, states);
        });
    }
}

export class ProductionGraphDataShift {
    /**
     * One letter label of the shift.
     */
    label: string;

    /**
     * Start time of the shift in format HH:mm.
     */
    start: string;

    /**
     * Shift length in hours. Decimal.
     */
    lengthInHours: number;

    /**
     * Machine states during the shift.
     */
    states: Array<ProductionGraphDataState>;

    /**
     * Production Graph Data Shift
     * @param label
     * @param start
     * @param {string} lengthInHours
     * @param {Array<ProductionGraphDataState>} states
     */
    constructor(label: string, start: string, lengthInHours: number, states: Array<ProductionGraphDataState>) {
        this.label = label.substr(0, 1).toUpperCase();
        this.start = start;
        this.lengthInHours = lengthInHours;
        this.states = states;
    }
}

export class ProductionGraphDataState {
    /**
     * A machine state.
     */
    state: MachineState;

    /**
     * How long this state persisted in hours.
     */
    lengthInHours: number;

    /**
     * Production Graph Data State
     * @param {MachineState} state
     * @param lengthInHours
     */
    constructor(state: MachineState, lengthInHours: number) {
        this.state = state;
        this.lengthInHours = lengthInHours;
    }
}