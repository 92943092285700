import { AframeComponent } from "./AframeComponent";
import * as $ from "jquery";
import {config} from "../../../config";

/// Displays a loading text before a 3D model is loaded.
/// Apply this component to the parent of the 3D model.
export const ModelLoadingText = new AframeComponent("model-loading-text", {
    loadingIndicator: HTMLElement,
    schema: {
        type: 'string',
        default: 1
    },
    init: function () {
        this.addLoadingIndicator();
        const model: HTMLElement = this.el.querySelector('a-obj-model');
        model.addEventListener('model-loaded', () => this.hideLoadingIndicator());
    },
    addLoadingIndicator: function() {
        const loadingText = this.data;
        const parent = this.el.parentElement;
        const position = this.el.object3D.position;
        this.loadingIndicator = $(`<a-entity 
                position="${position.x} ${position.y} ${position.z}"
                text="width: 3; color: ${config.colors.primary}; value: ${loadingText}; align: center; anchor: align; font: ${config.fonts.bold}; negate: false;"></a-entity>`)[0];
        parent.appendChild(this.loadingIndicator);
    },
    hideLoadingIndicator: function() {
        this.loadingIndicator.object3D.visible = false;
    },
});