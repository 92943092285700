import {ProjectDto} from "./ProjectDto";
import { MachineState } from "./MachineState";

export class MachineSimpleDto {
    machineId: number;
    machineName: string;
    marker: string;
    markerLevel: number;

    efficiency: number;
    state: MachineState;
    remainingCycleTime: number;
    manufacturingCycleDurationMilliseconds: number;

    workerId: string;
    workerName: string;
    workerPhotoUrl: string;

    projectName: string;
    projectModelUrl: string;
    projectProducedPieces: number;
    projectPlannedPieces: number;
}