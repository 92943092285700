import {ARController} from "../../ARController";
import {MachineMessage, IMessage, RequestAllMachinesMessage, AuthenticationRequestMessage, AuthenticationResponseMessage, InfoMessage} from "../../../common/sync/Message";
import {dataMessageTypes, MessageType} from "../../../common/sync/MessageType";
import {IDataProvider} from "./IDataProvider";
import { SecuredBrowserSocketClient } from "./websocket/SecuredBrowserSocketClient";
import * as $ from "jquery";
import { container } from "tsyringe";
import { StatusView } from "../../ui/StatusView";

export class ServerDataProvider implements IDataProvider {

    readonly serverUrl = `ws${window.location.protocol === "https:" ? "s" : ""}://${window.location.host}`;

    onInitialDataLoad = () => {};

    private controller: ARController;
    private socket: SecuredBrowserSocketClient;
    private queue: Array<IMessage> = [];
    private isPaused = true;
    private statusView = container.resolve(StatusView);

    /**
     * Init function - connect to the backend
     */
    init(controller: ARController) {
        this.controller = controller;

        this.socket = new SecuredBrowserSocketClient(this.serverUrl, true);
        
        this.socket.on('message', (m: any) => this.onMessage(m));
        this.socket.on('authenticated', (m: AuthenticationResponseMessage) => this.onSuccessfullAuthentication(m));
        this.socket.on('connected', () => this.onConnect());
        this.socket.on('disconnected', () => this.onDisconnect())

        this.socket.connect();
    }

    onConnect() {
        this.statusView.hide();
    }

    onDisconnect() {
        this.statusView.show(this.controller.languages.currentLanguage.odpojen);
    }

    /**
     * Pauses processing of incoming messages. Messages are stored in queue until the sync is started again.
     */
    pause() {
        this.isPaused = true;
    }

    /**
     * Starts receiving and processing messages.
     */
    start() {
        let message: IMessage;

        while (message = this.queue.shift()) {
            console.log("[Sync] Processing queued message", message);
            this.processUpdateMessage(message);
        }

        this.isPaused = false;
    }

    /**
     * On Message WS Event when the message is JSON
     * @param {any} json
     */
    private onMessage(message: IMessage) {
        if (message.type === MessageType.allData) {
            this.controller.data.update(message);
            this.onInitialDataLoad();
            this.controller.recreateAllViews();
        } else if (dataMessageTypes.includes(message.type)) {
            if (this.isPaused) {
                console.warn('[Sync] Sync paused, storing message to queue.');
                this.queue.push(message);
            } else {
                this.processUpdateMessage(message)
            }
        }
    }

    private onSuccessfullAuthentication(message: AuthenticationResponseMessage) {
        this.requestAllData();
    }

    private processUpdateMessage(message: IMessage) {
        this.controller.data.update(message);
        const machineMessage = <MachineMessage>message;
        const machineId = machineMessage.machineId;
        this.controller.updateView(machineId);
    }

    /**
     * Request all initial data from the backend
     */
    requestAllData() {
        console.log(`[Sync] Requesting all data from the server.`);
        this.socket.send(new RequestAllMachinesMessage());
    }
}
