export const config = {
    developmentPort: 3001,                  // app will run on this port when you run `npm run start`
    enableSync: true,                       // download data from the backend, if false use fake data instead; may be overridden in URL
    socketConnectionAttemptTimeout: 10000,  // ms, how long to wait before the next connection attempt after a failed one
    dataProviderUrl:                        // another web socket server providing data API
        process.env.DATA_PROVIDER ? process.env.DATA_PROVIDER : 'wss://ar-api.spatialhub.cz/machine',
    fileServerProxy:                        // if the file server is accessible only via proxy, enter proxy setting here to fix URLs in data messages; leave both values empty for no proxy
        {fileServer: 'http://ar.mediaefekt.cz:8090', proxyServer: 'https://ar-fileserver.spatialhub.cz'},
    logLevel: 'debug',                      // only this level or higher will be printed to console
    logColors: true,                        // whether to use colors in log, not supported in all environments
    logMessages: false,                      // whether to print incoming messages' content into log; must be FALSE in production deployment, logs may be large and contain confidential data!
    autoHideMachine: 1000,                  // ms, after a marker is lost, how long to wait before hiding the machine's view
    followAnimationSpeed: 0.05,             // meters per frame, how fast should a machine view fly when moving along with FOV
    followAnimationThreshold:               // animation will be started when the view moved at least X meters from the FOV center, x=horizontally (left/right), z=horizontally (close/far)
        {x: 0.1, z: 0.5},
    machineStateHistoryDays: 3,             // how many days to keep and show in the production graph
    refreshDataInterval: 6,                 // each X hours re-download all data from Media Efekt API, this refresh also updates markers assigned to machines
    useHttpsInLocalMode: false,             // use HTTPS in local mode (for Android and Google Glass)
    useHttpsInProductionMode: false,        // use HTTPS in production mode (for Android and Google Glass)
    refreshTimeInViewsInterval: 5000,       // refresh time-based information in rendered views each X ms
    refreshProductionGraphInterval: 60000,  // refresh production graph in rendered views each X ms
    autoDisconnectTimeout: 10000,           // how long to wait before a socket connection is lost, in ms
    arJsOptions:                            // AR.js settings
        'detectionMode: mono_and_matrix;' +
        'matrixCodeType: 4x4_BCH_13_9_3;' +
        'maxDetectionRate: 30;',

    colors: {
        primary: 'white',
        busy: '#9BC53D',
        idle: '#FDE74C',
        maintenance: '#A97AFF',
        malfunction: '#E55934',
        adjustment: '#FF9100',
        waitingForControl: '#5BC0EB',
        textGray: '#878787',
    },

    fonts: {
        primary: '/fonts/aframe/Roboto-Regular-msdf.json',
        bold: '/fonts/aframe/Roboto-Bold-msdf.json',
    },

    shifts: [
        {
            label: 'A',
            start: {hour: 6, minute: 0},
            lengthInHours: 8.0
        },
        {
            label: 'B',
            start: {hour: 14, minute: 0},
            lengthInHours: 8.0
        },
        {
            label: 'C',
            start: {hour: 22, minute: 0},
            lengthInHours: 8.0
        }
    ]
};