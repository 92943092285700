import {WorkerPerson} from "../model/WorkerPerson";
import {MessageType} from "../sync/MessageType";
import {ProjectDto} from "../model/ProjectDto";
import {MachineDto} from "../model/MachineDto";
import {MachineStateHistoryDto} from "../model/MachineStateHistoryDto";

export interface IMessage {
    type: MessageType;
}

export class PingMessage implements IMessage {
    type = MessageType.ping;
}

export class PongMessage implements IMessage {
    type = MessageType.pong;
}

export class AuthenticationRequestMessage implements IMessage {
    type = MessageType.authRequest;
    password: string;

    constructor(password: string) {
        this.password = password;
    }
}

export class AuthenticationResponseMessage implements IMessage {
    type = MessageType.authResponse;
    authenticated: boolean;
    fileServerPassword: string;

    constructor(authenticated: boolean, fileServerPassword: string = null) {
        this.authenticated = authenticated;
        this.fileServerPassword = fileServerPassword;
    }
}

export class RequestAllMachinesMessage implements IMessage {
    type = MessageType.requestAllData;
}

export class InfoMessage implements IMessage {
    type = MessageType.info;
    info: string;

    constructor(info: string) {
        this.info = info;
    }
}

export class AllDataMessage implements IMessage {
    type = MessageType.allData;
    machines: Array<MachineDto>;

    constructor(machines: Array<MachineDto>) {
        this.machines = machines;
    }
}

export abstract class MachineMessage implements IMessage {
    type: MessageType;
    machineId: number;
}

export class MachineWorkerMessage extends MachineMessage {
    type = MessageType.machineWorker;
    worker: WorkerPerson;
}

export class MachineStateMessage extends MachineMessage {
    type = MessageType.machineState;
    stateHistory: MachineStateHistoryDto;
}

export class MachineEfficiencyMessage extends MachineMessage {
    type = MessageType.machineEfficiency;
    efficiency: number;
}

export class MachineProjectsMessage extends MachineMessage {
    type = MessageType.machineProjects;
    projects: Array<ProjectDto>;
}

export class MachineProjectModelUrlMessage extends MachineMessage {
    type = MessageType.machineProjectModelUrl;
    projectName: string;
    modelUrl: string;
}

export interface MachineProjectPiecesMessage extends MachineMessage {
    projectName: string;
    pieces: number;
    rejects: number;
}

export interface MachineMarkerLevelMessage extends MachineMessage {
    markerLevel: number;
}

