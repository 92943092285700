import {MachineState} from "../../../common/model/MachineState";
import {
    ProductionGraphData,
    ProductionGraphDataForOneDay,
    ProductionGraphDataShift, ProductionGraphDataState
} from "./Models/ProductionGraphData";
import {formatDuration} from "../../../common/utils";
import {Project} from "../../../common/model/Project";
import {LanguageModel} from "../../languages/LanguageModel";
import {Machine} from "../../../common/model/Machine";
import {URLParams} from "../../services/URLParams";
import {config} from "../../../config";

export interface IElements {
    /**
     * Progress Bar
     * @param {string} minLabel
     * @param {string} maxLabel
     * @param {number} width
     * @param {number} value
     * @returns {string}
     */
    progressBar(minLabel: string, maxLabel: string, width: number, value: number): string ;

    productionInfo(project: Project, yOffset: number, language: LanguageModel): string ;

    productionCycleInfo(remainingCycleTime: number, elapsedCyclePercentage: number, yOffset: number, language: LanguageModel): string ;

    efficiencyInfo(efficiency: number, yOffset: number, language: LanguageModel): string ;

    productionGraph(machine: Machine, dayCount: number, yOffset: number, language: LanguageModel): string ;

    /**
     * Day Production Graph
     * @param {ProductionGraphDataForOneDay} day
     * @param yOffset
     * @returns {string}
     */
    dayProductionGraph(day: ProductionGraphDataForOneDay, yOffset: number): string ;

    profilePhoto(src: string, position?: string, width?: number, height?: number, language?: LanguageModel): string;
}

export class Elements3D implements IElements {

    /**
     * UI Element that shows a horizontal progress bar.
     * @param {string} minLabel
     * @param {string} maxLabel
     * @param {number} width
     * @param {number} value In percents (0-100)
     * @returns {string} 
     */
    progressBar(minLabel: string, maxLabel: string, width: number, value: number): string {
        const barWidth = width * value / 100;
        const barPositionX = - (width / 2) + (barWidth / 2);
        const barVisibility = barWidth > 0; // a-frame elements cannot has 0 width

        return `
            <a-entity position="${width / 2} 0 0">
            
                <!-- Background -->
                <a-entity position="0 0.16 0" geometry="primitive: plane; height: 0.2; width: ${width};" material="color: ${config.colors.primary}; opacity: 0.5; shader: flat;"></a-entity>
                
                <!-- Bar -->
                <a-entity visible="${barVisibility}" position="${barPositionX} 0.16 0.01" geometry="primitive: plane; height: 0.2; width: ${barWidth}" material="color: ${config.colors.primary}; shader: flat;"></a-entity>
                
                <!-- Labels -->
                <a-entity position="${-width / 2} 0 0" text="width: 1.6; color: ${config.colors.primary}; value: ${minLabel}; anchor: align; font: ${config.fonts.bold}; negate: false"></a-entity>
                <a-entity position="${width / 2} 0 0" text="width: 1.6; color: ${config.colors.primary}; value: ${maxLabel}; align: right; anchor: align; font: ${config.fonts.bold}; negate: false"></a-entity>
                
            </a-entity>
        `;
    }

    productionInfo(project: Project, yOffset: number, language: LanguageModel): string {
        let totalPieces = "-";
        let producedPieces = "-";
        let percentage = 0;

        if (project) {
            totalPieces = project.plannedPieces.toString();
            producedPieces = (project.pieces - project.rejects).toString();
            percentage = (project.pieces - project.rejects) / project.plannedPieces;
        }

        return `
        <a-entity id="produced-goods" position="-2.2 ${yOffset} 0" rotation="0 0 0">
            <a-entity position="0 0.5 0" text="width: 2; color: ${config.colors.primary}; value: ${language.vyrobenoKs}; align: left; anchor: align; font: ${config.fonts.primary}; negate: false;"></a-entity>
            <a-entity position="0 0.38 0" text="width: 2; color: ${config.colors.primary}; value: ${producedPieces} / ${totalPieces}; align: left; anchor: align; font: ${config.fonts.bold}; negate: false;"></a-entity>
            ` + this.progressBar('0', totalPieces.toString(), 1.17, 100 * percentage) + `
        </a-entity>
        `;
    }

    productionCycleInfo(remainingCycleTime: number, elapsedCyclePercentage: number,  yOffset: number, language: LanguageModel): string  {
        let remainingTime = remainingCycleTime ? formatDuration(remainingCycleTime) : '-';

        return `
        <a-entity id="production-cycle" position="-0.7 ${yOffset} 0" rotation="0 0 0">
            <a-entity position="0 0.5 0" text="width: 2; color: ${config.colors.primary}; value: ${language.caseDoKonceCyklu}; align: left; anchor: align; font: ${config.fonts.primary}; negate: false;"></a-entity>
            <a-entity position="0 0.38 0" text="width: 2; color: ${config.colors.primary}; value: ${remainingTime}; align: left; anchor: align; font: ${config.fonts.bold}; negate: false;"></a-entity>

            ` + this.progressBar('0%', '100%', 1.17, elapsedCyclePercentage) + `
        </a-entity>
        `;
    }

    efficiencyInfo(efficiency: number, yOffset: number, language: LanguageModel): string  {
        const percentage = Math.max(0, Math.min(100, efficiency));
        const value = efficiency === null ? "-" : percentage + "%";

        return `
        <a-entity id="efficiency" position="0.8 ${yOffset} 0" rotation="0 0 0">
            <a-entity position="0 0.5 0" text="width: 2; color: ${config.colors.primary}; value: ${language.produktivitaStroje}; align: left; anchor: align; font: ${config.fonts.primary}; negate: false;"></a-entity>
            <a-entity position="0 0.38 0" text="width: 2; color: ${config.colors.primary}; value: ${value}; align: left; anchor: align; font: ${config.fonts.bold}; negate: false;"></a-entity>

            ` + this.progressBar('0', '100%', 1.17, percentage) + `
        </a-entity>
        `;
    }

    productionGraph(machine: Machine, dayCount = 3, yOffset: number, language: LanguageModel): string  {
        let data = new ProductionGraphData(machine, dayCount);

        return `
        <a-entity id="production-graph" position="0 ${yOffset} 0" rotation="0 0 0">
            <a-entity position="0 1 0.01" text="width: 4; color: ${config.colors.primary}; value: ${language.prubehVyroby} ${machine.machineName.toUpperCase()}; align: center; anchor: center; font: ${config.fonts.primary}; negate: false;"></a-entity>
            ` + data.map((day, dayIndex) =>
            this.dayProductionGraph(day, 0.7 - dayIndex * 0.5)
        ).join("") + `
            </a-entity>
        </a-entity>
        `;
    }

    /**
     * Day Production Graph
     * @param {ProductionGraphDataForOneDay} day
     * @param {number} yOffset
     * @returns {string}
     */
    dayProductionGraph(day: ProductionGraphDataForOneDay, yOffset: number): string  {
        const availableWidth = 3.8;
        const xOffset = -2.3;
        let x = xOffset;

        return `
            <a-entity position="0 ${yOffset} 0">
                <a-entity position="${xOffset} 0 0" text="width: 2; color: ${config.colors.primary}; value: ${day.formattedDate}; align: left; anchor: align; font: ${config.fonts.bold}; negate: false;"></a-entity>
                ` + day.shifts.map((shift: ProductionGraphDataShift, index: number) => {

            const shiftWidth = availableWidth * (shift.lengthInHours / 24);
            x += shiftWidth;
            let blockX = 0;

            const renderStateBlocks = () => shift.states.map((state: ProductionGraphDataState) => {
                if (state.lengthInHours == 0) { return ''; }
                const w = (shiftWidth) * (state.lengthInHours / shift.lengthInHours);
                if (w < 0.0001) { return ''; } // skip too small changes (< 4 min) to prevent performance problems
                blockX += w;
                return `<a-plane position="${blockX - (w / 2)} 0 0" material="color: ${MachineState.colorOf(state.state)}; shader: flat;" width="${w}" height="0.15"></a-plane>`;
            }).join('');

            return `
                    <a-entity position="${x - shiftWidth} 0 0">
                        <a-entity position="0.58 0.09 0" text="width: 2; color: ${config.colors.primary}; value: ${shift.start}; align: left; anchor: align; font: ${config.fonts.primary}; negate: false;"></a-entity>
                        <a-plane position="0.515 0 0" material="color: ${config.colors.primary}; shader: flat;" width="0.015" height="0.3"></a-plane>
                        <a-entity position="0.52 -0.075 0">
                            ` + renderStateBlocks() + `
                        </a-entity>
                    </a-entity>
                `;
        }).join('') + `
            </a-entity>
        `;
    }

    profilePhoto(src: string, position: string = "0 0 0", width: number = 1, height: number = 1, language: LanguageModel = null): string {
        if (src === null) {
            return '';
        }

        const loadingText = language ? `image-loading-text="${language.nacitani}..."` : ``;

        if (src.match(/\.gif[^/]*$/) !== null) {
            return `<a-entity position="${position}" geometry="primitive: plane; width: ${width}; height: ${height};" material="shader:gif;src:url(${src});" gif="" ${loadingText}></a-entity>`;
        } else {
            return `<a-image position="${position}" width="${width}" height="${height}" src="${src}" ${loadingText}></a-image>`;
        }
    }
}


export class Elements2D implements IElements {
    /**
     * Progress Bar
     * @param {string} minLabel
     * @param {string} maxLabel
     * @param {number} width
     * @param {number} value In percents (0-100)
     * @returns {string}
     */
    progressBar(minLabel: string, maxLabel: string, width: number, value: number): string {
        return `
            <div class="row">
                <progress max="100" value="${value}"></progress>&nbsp;
                <!-- Labels -->
                <!--<div style="margin-top: ${-width / 2}; width: 1.6px; color: ${config.colors.primary};">${minLabel}</div>
                <div style="margin-top: ${width / 2}; width: 1.6px; color: ${config.colors.primary}; text-align: right;">${maxLabel}</div>-->
            </div>
        `;
    }

    /**
     * Get Production Info
     * @param project
     * @param {number} yOffset
     * @param language
     * @returns {string}
     */
    productionInfo(project: Project, yOffset: number, language: LanguageModel): string {
        let totalPieces = "-";
        let producedPieces = "-";
        let percentage = 0;

        if (project) {
            totalPieces = project.plannedPieces.toString();
            producedPieces = (project.pieces - project.rejects).toString();
            percentage = (project.pieces - project.rejects) / project.plannedPieces;
        }

        return `<div class="produced-goods col-sm-4">
                    <div class="produced-goods-text">${language.vyrobenoKs}<br>${producedPieces} / ${totalPieces}</div>
                    ` + this.progressBar('0', totalPieces.toString(), 1.17, 100 * percentage) + `
                </div>`;
    }

    /**
     * Get Production Cycle Info
     * @param remainingCycleTime
     * @param elapsedCyclePercentage
     * @param {number} yOffset
     * @param language
     * @returns {string}
     */
    productionCycleInfo(remainingCycleTime: number, elapsedCyclePercentage: number,  yOffset: number, language: LanguageModel): string {
        let remainingTime = remainingCycleTime ? formatDuration(remainingCycleTime) : '-';

        return `<div id="production-cycle" class="remaining-time col-sm-4">
                    <div class="remaining-time-text">${language.caseDoKonceCyklu}</div>
                    <div class="remaining-time-text">${remainingTime}</div>
                    ` + this.progressBar('0%', '100%', 1.17, elapsedCyclePercentage) + `
                </div>`;
    }

    /**
     * Get Efficiency Info
     * @param efficiency
     * @param {number} yOffset
     * @param language
     * @returns {string}
     */
    efficiencyInfo(efficiency: number, yOffset: number, language: LanguageModel): string {
        const percentage = Math.max(0, Math.min(100, efficiency));
        const value = efficiency === null ? "-" : percentage + "%";

        return `
        <div class="efficiency col-sm-4" style="top: ${yOffset};">
            <div class="efficiency-text">${language.produktivitaStroje}</div>
            <div class="efficiency-text">${value}</div>
            ` + this.progressBar('0', '100%', 1.17, percentage) + `
        </div>
        `;
    }

    /**
     * Get Production Graph
     * @param machine
     * @param {number} dayCount
     * @param {number} yOffset
     * @param language
     * @returns {string}
     */
    productionGraph(machine: Machine, dayCount = 3, yOffset: number, language: LanguageModel): string {
        let data = new ProductionGraphData(machine, dayCount);

        return `<div id="production-graph" class="production-graph row">
                    <div class="production-graph-text col-sm-12">${language.prubehVyroby} ${machine.machineName}</div>
                    ` + data.map((day, dayIndex) => this.dayProductionGraph(day, 0.7 - dayIndex * 0.5)).join('') + `</div>
                </div>`;
    }

    /**
     * Day Production Graph
     * @param {ProductionGraphDataForOneDay} day
     * @param yOffset
     * @returns {string}
     */
    dayProductionGraph(day: ProductionGraphDataForOneDay, yOffset: number): string {
        const availableWidth = window.innerWidth / 2;
        const xOffset = -2.3;
        let x = xOffset;

        return `<div class="col-sm-12 row no-gutters">
        <div class="col-sm-3">${day.formattedDate}</div>`
            + day.shifts.map((shift: ProductionGraphDataShift, index: number) => {
                const shiftWidth = availableWidth * (shift.lengthInHours / 24);
                x += shiftWidth;
                let blockX = 0;

                const renderStateBlocks = () => shift.states.map((state: ProductionGraphDataState) => {
                    if (state.lengthInHours == 0) { return ''; }
                    const w = (shiftWidth - 0.07) * (state.lengthInHours / shift.lengthInHours);
                    blockX += w;
                    return `<span style="margin-left: ${blockX - (w / 2)}; background-color: ${MachineState.colorOf(state.state)}; width: ${w}px; height: 10px;">&nbsp;</span>`;
                }).join('');

                return `<div class="col-sm-3">
                            <div class="row" style="">${shift.start}</div>
                            <div class="row">${renderStateBlocks()}</div>
                        </div>`;
            }).join('') + `</div>`;
    }

    profilePhoto(src: string, position: string = null, width: number = null, height: number = null, language: LanguageModel = null): string {
        if (src === null) {
            return '';
        }

        return `<img src="${src}" alt="">`;
    }
}

export const Elements: IElements = (URLParams.IsGoogleGlass) ? new Elements2D() : new Elements3D();
