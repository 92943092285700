import { AframeComponent } from "./AframeComponent";
import { container } from "tsyringe";
import { ARController } from "../../ARController";
import { THREE } from "aframe";
import { config } from "../../../config";
import { URLParams } from "../../services/URLParams";

const showMachineView = (machineId: string) => {
    const el = document.getElementById('machine-' + machineId);
    if (URLParams.IsGoogleGlass) {
        el.style.display = 'block';
    } else {
        el.setAttribute('visible', 'true');
    }
}

const hideMachineView = (machineId: string) => {
    const el = document.getElementById("machine-" + machineId);
    if (URLParams.IsGoogleGlass) {
        el.style.display = 'none';
    } else {
        el.setAttribute('visible', 'false');
    }
}

const dismissAllOthers = (recognized: string) => {
    let els = document.getElementsByClassName("machine");
    for (let el of els) {
        const machineId = el.id.replace("machine-", "");
        if (machineId !== recognized) {
            hideMachineView(machineId);
            el.dispatchEvent(new Event('updateIfNeeded'));
        }
    }
}

export const MarkerDetectionComponent = new AframeComponent("markerdetection", {
    init: function () {
        const that = this;

        that.timeout = null;

        if (!URLParams.IsGoogleGlass) {
            that.camera = document.querySelector('#camera').object3D;
            that.targetPosition = document.querySelector('#targetPosition');
            that.worldPos = new THREE.Vector3();
        }

        const marker = this.el;
        const markerId = marker.id.substr(7);
        const ar = <ARController>container.resolve(ARController);

        marker.addEventListener('markerFound', function () {
            console.log('markerFound', markerId);

            const machineView = <any>document.getElementById('machine-' + markerId);
            const machineId = parseInt(machineView.getAttribute("machine-id"));
            const machineData = ar.data.findMachine(machineId);

            const isDifferentMarker = markerId != ar.currentMarker;

            if (isDifferentMarker) {
                if (!URLParams.IsGoogleGlass) {
                    ar.currentMarker = markerId;
                }
                dismissAllOthers(markerId);
            }

            if (!URLParams.IsGoogleGlass) {
                // read current FOV target
                that.worldPos.setFromMatrixPosition(that.targetPosition.object3D.matrixWorld);

                // get marker level
                const viewLevel = machineData.markerLevel;

                // reset view position - immediately show right in the center of FOV
                // do not reset the position if the object is still visible, otherwise it would cause sudden jumps during the animation
                if (!machineView.object3D.visible) { 
                    machineView.object3D.position.x = that.worldPos.x;
                    machineView.object3D.position.y = viewLevel / 100; // follow strategy has Y fixed at the marker position above the ground
                    machineView.object3D.position.z = that.worldPos.z;
                }

                // default rotation after the marker is found
                const rotY = Math.atan2((that.camera.position.x - that.worldPos.x), (that.camera.position.z - that.worldPos.z));
                machineView.object3D.rotation.y = rotY;
            }

            showMachineView(markerId);
            
            machineView.dispatchEvent(new Event('updateIfNeeded'));

            if (that.timeout) {
                clearTimeout(that.timeout);
            }
        });

        marker.addEventListener('markerLost', function () {
            const markerId = marker.id.substr(7);
            console.log('markerLost', markerId);

            that.timeout = setTimeout(() => {
                hideMachineView(markerId);
                that.timeout = null;
            }, config.autoHideMachine);
        });
    }
});
