export class EventEmitter {

    private listeners = new Map<string, Array<Function>>();

    on(event: string, listener: Function): this {
        if (!this.listeners.get(event)) {
            this.listeners.set(event, new Array<Function>());
        }

        this.listeners.get(event).push(listener);

        return this;
    }

    emit(event: string, ...args: any[]) {
        const listeners = this.listeners.get(event);

        if (listeners) {
            listeners.forEach(l => l(...args));
        }
    }
}