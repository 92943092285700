import { Scene } from "./Scene";

export class ARScene extends Scene {
    
    protected getHtmlString(): string {
        return `
        <a-scene arjs="${this.arJsConfig}" canvas="" id="scene" device-orientation-permission-ui="enabled: false">
            <a-camera id="camera" look-controls>
                <a-entity id="targetPosition" position="0 0 -5" rotation="0 0 0"></a-entity>
            </a-camera>
            <a-assets id="assets"></a-assets>
            <a-entity id="markers" markers></a-entity>
        </a-scene>
        `;
    }

}