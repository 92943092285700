const technicianIcon = require("../assets/technician.svg");
const managerIcon = require("../assets/manager.svg");
import { container, singleton } from "tsyringe";
import { ARController } from "../ARController";
import { URLParams } from "../services/URLParams";
import ViewType from "../components/gui/Models/ViewType";
import FollowStrategy from "../components/gui/Models/FollowStrategy";
import 'aframe-gif-shader';
import 'aframe-gif-component';
import { Button } from "./components/Button";
import { Switch } from "./components/Switch";
import { LanguageModel } from "../languages/LanguageModel";
import { NumberInput } from "./components/NumberInput";
import * as $ from 'jquery';

@singleton()
export class ControlPanel {

    protected ar = container.resolve(ARController);

    protected rootElement: HTMLElement;
    protected positionSwitch: Switch<FollowStrategy>;
    protected layoutSwitch: Switch<ViewType>;
    protected languageSwitch: Switch<LanguageModel>;
    protected operatorHeight: NumberInput;
    protected startButton: Button;

    attach() {
        // Root element
        this.rootElement = $(this.htmlString)[0];
        document.body.appendChild(this.rootElement);

        // Interface layout
        this.layoutSwitch = new Switch<ViewType>("Interface layout", "interface-layout", [
            {title: 'Worker', icon: technicianIcon, value: ViewType.Worker},
            {title: 'Manager', icon: managerIcon, value: ViewType.Manager}
        ]);
        this.layoutSwitch.on('change', t => this.onInterfaceLayoutChange(t));
        this.layoutSwitch.attachTo(this.rootElement);

        // Interface language
        this.languageSwitch = new Switch<LanguageModel>("Interface language", "interface-language", 
            this.ar.languages.languages.map(language => (
                {title: language.name, value: language}
            ))
        );
        this.languageSwitch.on('change', l => this.onInterfaceLanguageChange(l));
        this.languageSwitch.attachTo(this.rootElement);

        // Start button
        this.startButton = new Button("Start", "", "start");
        this.startButton.on('click', () => this.onStartButtonClick());
        this.startButton.attachTo(this.rootElement);
    }

    show() {
        if (this.rootElement) {
            this.rootElement.style.display = "block";
            this.update();
        } else {
            console.error("Swithes not attached!");
        }
    }

    hide() {
        if (this.rootElement) {
            this.rootElement.style.display = "none";
        }
    }

    protected get htmlString(): string {
        return `<div id="control-panel"></div>`;
    }

    protected onInterfaceLayoutChange(viewType: ViewType) {
        this.ar.changeViewType(viewType);
        this.update();
    }

    protected onInterfaceLanguageChange(language: LanguageModel) {
        this.ar.setLanguage(language);
        this.update();
    }

    protected onStartButtonClick() {
        this.ar.startVR();
    }

    /**
     * Update control panel styles to reflect current app state.
     */
    update() {
        this.layoutSwitch.value = this.ar.currentView;
        this.languageSwitch.value = this.ar.languages.currentLanguage;

        URLParams.update(
            false, 
            this.ar.currentView, 
            this.ar.languages.currentLanguage.locale
        );
    }

}