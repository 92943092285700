import {Moment} from "moment";
import moment = require("moment");
import {MachineState} from "./MachineState";
import { MachineStateChangeDto } from "./MachineStateChangeDto";

export class MachineStateChange {
    time: Moment;
    state: MachineState;
    isManufacturingCycleStart?: Boolean;
    isManufacturingCycleEnd?: Boolean;

    constructor(time: Moment, state: MachineState, isManufacturingCycleStart?: Boolean, isManufacturingCycleEnd?: Boolean) {
        this.time = time;
        this.state = state;
        this.isManufacturingCycleStart = isManufacturingCycleStart;
        this.isManufacturingCycleEnd = isManufacturingCycleEnd;
    }

    static fromDto(dto: MachineStateChangeDto, isoTime: string) {
        const withoutTimezone = isoTime.replace("[CET]", "");
        const time = moment(withoutTimezone);
        const state = <MachineState>dto.state;

        return new MachineStateChange(
            time,
            state,
            dto.isManufacturingCycleStart,
            dto.isManufacturingCycleEnd
        );
    }
}