import { MachineView3D, MachineView2D } from "./MachineView";
import { Machine } from "../../../common/model/Machine";
import { Languages } from "../../languages/Languages";
import {MachineState} from "../../../common/model/MachineState";
import {Elements} from "./Elements";
import {AssetRegister} from "./Models/AssetRegister";
import {config} from "../../../config";

declare var $: any;

export class WorkerMachineView3D extends MachineView3D {
    /**
     * Worker Machine View 3D
     * @param {Machine} data
     * @param {Languages} languages
     * @param assetRegister
     */
    constructor(data: Machine, languages: Languages, assetRegister: AssetRegister) {
        super(data, languages, assetRegister);
    }

    /**
     * Get Inner HTML String
     * @returns {string}
     */
    getInnerHtmlString(): string {
        return (
            this.getMachineState() +
            this.getWorkerProfile() +
            this.getProductionInfo() +
            this.getProductionCycleInfo() +
            this.getEfficiencyInfo() +
            this.getProductionGraph()
        );
    }

    attachView() {
        super.attachView();
        this.keepFresh(() => this.getProductionCycleInfo(), config.refreshTimeInViewsInterval);
        this.keepFresh(() => this.getProductionGraph(), config.refreshProductionGraphInterval);
    }

    /**
     * Get Machine State
     * @returns {string}
     */
    public getMachineState(): string {
        const state = this.machine.currentState;
        const localizedState = this.languages.currentLanguage.getLocalizedMachineState(state);
        const project = this.machine.currentProject;
        const projectNumber = project ? project.productNumber : "-";
        let icon = "#undefined-state";

        switch (state) {
            case MachineState.busy: icon = "#busy-state"; break;
            case MachineState.idle: icon = "#idle-state"; break;
            case MachineState.maintenance: icon = "#maintenance-state"; break;
            case MachineState.malfunction: icon = "#malfunction-state"; break;
            case MachineState.adjustment: icon = "#adjustment-state"; break;
            case MachineState.waitingForControl: icon = "#waitingforcontrol-state"; break;
        }

        return `
        <a-entity id="machine-state" position="-1.5 0 0" rotation="0 0 0">
            <a-image position="0 0 0" width="0.4" height="0.4" src="${icon}"></a-image>
            <a-entity position="0.3 0.1 0" text="width: 3; color: ${config.colors.primary}; value: ${localizedState}; align: left; anchor: align; font: ${config.fonts.primary}; negate: false;"></a-entity>
            <a-entity position="0.3 -0.05 0" text="width: 3; color: ${config.colors.primary}; value: ${projectNumber}; align: left; anchor: align; font: ${config.fonts.bold}; negate: false;"></a-entity>
        </a-entity>
        `;
    }

    /**
     * Get Worker Profile
     * @returns {string}
     */
    public getWorkerProfile(): string {
        let workerName = "-";

        if (this.machine.hasWorker) {
            workerName = this.machine.worker.sanitizedName;
        }

        return `
        <a-entity id="worker-profile" position="0.5 0 0" rotation="0 0 0">
            ${Elements.profilePhoto(this.profilePictureSrc, "0 0 0", 0.4, 0.4, this.languages.currentLanguage)}
            <a-entity position="0.3 0.1 0" text="width: 3; color: ${config.colors.primary}; value: ${this.languages.currentLanguage.prihlasen}; align: left; anchor: align; font: ${config.fonts.primary}; negate: false;"></a-entity>
            <a-entity position="0.3 -0.05 0" text="width: 3; color: ${config.colors.primary}; value: ${workerName}; align: left; anchor: align; font: ${config.fonts.bold}; negate: false;"></a-entity>
        </a-entity>
        `;
    }

    public getProductionInfo() {
        return Elements.productionInfo(this.machine.currentProject, -0.9, this.languages.currentLanguage);
    }

    public getProductionCycleInfo() {
        return Elements.productionCycleInfo(this.machine.remainingCycleTime, this.machine.elapsedCyclePercentage, -0.9, this.languages.currentLanguage);
    }

    public getEfficiencyInfo() {
        return Elements.efficiencyInfo(this.machine.efficiency, -0.9, this.languages.currentLanguage);
    }

    public getProductionGraph() {
        return Elements.productionGraph(this.machine, 3, -2.2, this.languages.currentLanguage);
    }
}


export class WorkerMachineView2D extends MachineView2D {
    /**
     * Worker Machine View 2D
     * @param {Machine} data
     * @param {Languages} languages
     * @param assetRegister
     */
    constructor(data: Machine, languages: Languages, assetRegister: AssetRegister) {
        super(data, languages, assetRegister);
    }

    attachView() {
        super.attachView();
        this.keepFresh(() => this.getProductionCycleInfo(), config.refreshTimeInViewsInterval);
        this.keepFresh(() => this.getProductionGraph(), config.refreshProductionGraphInterval);
    }

    /**
     * Get Inner HTML String
     * @returns {string}
     */
    getInnerHtmlString(): string {
        return (
            `<div class="container">` +
            `<div class="row">` +
            this.getMachineState() +
            this.getWorkerProfile() +
            `</div>` +
            `<div class="row">` +
            this.getProductionInfo() +
            this.getProductionCycleInfo() +
            this.getEfficiencyInfo() +
            `</div>` +
            `<div class="row">` +
            this.getProductionGraph() +
            `</div>`+
            `</div>`
        );
    }

    /**
     * Get Machine State
     * @returns {string}
     */
    public getMachineState(): string {
        const state = this.machine.currentState;
        const localizedState = this.languages.currentLanguage.getLocalizedMachineState(state);
        const project = this.machine.currentProject;
        const projectNumber = project ? project.productNumber : "-";

        let icon = "";

        const assets = this.getAssets();

        const busyStateAsset = assets.find(asset => asset.id === "busy-state");
        const idleStateAsset = assets.find(asset => asset.id === "idle-state");
        const maintenanceStateAsset = assets.find(asset => asset.id === "maintenance-state");
        const malfunctionStateAsset = assets.find(asset => asset.id === "malfunction-state");
        const undefinedStateAsset = assets.find(asset => asset.id === "undefined-state");
        const adjustmentStateAsset = assets.find(asset => asset.id === "adjustment-state");
        const waitingForControlStateAsset = assets.find(asset => asset.id === "waitingforcontrol-state");

        switch (state) {
            case MachineState.busy: 
                icon = busyStateAsset.src; 
                break;
            case MachineState.idle: 
                icon = idleStateAsset.src; 
                break;
            case MachineState.maintenance: 
                icon = maintenanceStateAsset.src;
                break;
            case MachineState.malfunction:
                icon = malfunctionStateAsset.src;
                break;
            case MachineState.adjustment:
                icon = adjustmentStateAsset.src;
                break;
            case MachineState.waitingForControl:
                icon = waitingForControlStateAsset.src;
                break;
            default:
                icon = undefinedStateAsset.src;
                break;
        }

        return `<div class="machine-state col-sm-6">
                    <div class="row">
                        <div class="col-sm-3">
                            <img src="${icon}" alt="">
                        </div>
                        <div class="col-sm-9">
                            <div class="machine-state-text">${localizedState}</div>
                            <div class="machine-state-text">${projectNumber}</div>
                        </div>
                    </div>
                </div>`;
    }

    /**
     * Get Worker Profile
     * @returns {string}
     */
    public getWorkerProfile(): string {
        let workerName = "-";
        let profilePicture = "";

        if (this.machine.hasWorker) {
            workerName = this.machine.worker.sanitizedName;
        }

        return `<div class="worker-profile col-sm-6">
                    <div class="row">
                        <div class="col-sm-3">
                            ${Elements.profilePhoto(this.profilePictureSrc)}
                        </div>
                        <div class="col-sm-9">
                            <div class="worker-profile-text">${this.languages.currentLanguage.prihlasen}</div>
                            <div class="worker-profile-text">${workerName}</div>
                        </div>
                    </div>
                </div>`;
    }

    public  getProductionInfo() {
        return Elements.productionInfo(this.machine.currentProject, -0.9, this.languages.currentLanguage);
    }

    public  getProductionCycleInfo() {
        return Elements.productionCycleInfo(this.machine.remainingCycleTime, this.machine.elapsedCyclePercentage, -0.9, this.languages.currentLanguage);
    }

    public  getEfficiencyInfo() {
        return Elements.efficiencyInfo(this.machine.efficiency, -0.9, this.languages.currentLanguage);
    }

    public  getProductionGraph() {
        return Elements.productionGraph(this.machine, 3, -2.2, this.languages.currentLanguage);
    }
}
