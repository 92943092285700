import { LanguageModel } from "../LanguageModel";

const enl = new LanguageModel();

enl.name = "English";
enl.locale = "en";
enl.iconSource = "/img/english.png";

enl.probihaVyroba = "Working";
enl.porucha = "Malfunction";
enl.vUdrzbe = "Maintenance";
enl.vNecinnosti = "Idle";
enl.neznamy = "Unknown state";
enl.serizovani = "Adjustments";
enl.cekaniNaKontrolu = "Waiting for control";

enl.prihlasen = "Signed in:";
enl.prubehVyroby = "Production on Machine";
enl.vyrobenoKs = "Produced (pts):";
enl.caseDoKonceCyklu = "End of the Cycle:";
enl.produktivitaStroje = "Productivity:";
enl.nacitani = "Loading";
enl.odpojen = "DISCONNECTED";


export const englishLanguageModel = enl;
