import { config } from "../../config";

export const resourceUrl = (path: string): string => {
    if (!path) { return null; }

    const proxy = config.fileServerProxy;
    const password = localStorage.getItem('fileServerPassword');
    let newPath = path;

    if (proxy && proxy.fileServer && proxy.proxyServer) {
        newPath = newPath.replace(proxy.fileServer, proxy.proxyServer) 
    }

    if (password) {
        newPath += '?password=' + password;
    }

    return newPath;
}