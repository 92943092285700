import { AframeComponent } from "./aframe/AframeComponent";
import { AframeComponents } from "./aframe/AframeComponents";
import { machineFactory } from "./gui/MachineViewFactory";
import { IMachineView } from "./gui/MachineView";
import * as AFRAME from "aframe";
import { Machine } from "../../common/model/Machine";
import { ARController } from "../ARController";
import ViewType from "./gui/Models/ViewType";
import { config } from "../../config";
import { URLParams } from "../services/URLParams";
import { MarkerDetectionComponent } from "./aframe/MarkerDetection";
import {AssetRegister} from "./gui/Models/AssetRegister";
import { container } from 'tsyringe';

export class Components {
    /**
     * Instances of Aframe Components
     */
    aframeComponents = Array<AframeComponent>();

    /**
     * All GUI Components
     */
    machineViews = Array<IMachineView>();

    /**
     * AR Controller
     */
    controller: ARController;

    /**
     * Asset Register
     */
    assetRegister = container.resolve(AssetRegister);

    /**
     * AR Components
     * @constructor
     */
    constructor() { }

    /**
     * Init function
     */
    init(ar: ARController) {
        this.controller = ar;
        this.aframeComponents = AframeComponents;
    }

    /**
     * Attach All Components
     */
    attachAllComponents() {
        if (!URLParams.IsGoogleGlass) {
            this.attachAframeComponents();
        } else {
            AFRAME.registerComponent(MarkerDetectionComponent.selector, MarkerDetectionComponent.lifecycle);
        }
        this.attachMachineViews();
    }

    /**
     * Attach All A-FRAME Components
     */
    attachAframeComponents() {
        for (let component of this.aframeComponents) {
            AFRAME.registerComponent(component.selector, component.lifecycle);
        }
    }

    /**
     * Attach Machine Views
     */
    attachMachineViews() {
        for (let view of this.machineViews) {
            view.attach();
        }
    }

    /*
    attachMachineViews() {
        const $scene = (URLParams.IsGoogleGlass)? $('#html-scene')[0] : $('#scene')[0];
        for (let component of this.machineViews) {
            $scene.appendChild($(component.getHtmlString())[0]);
        }
    }

     */

    /**
     * Create Machine Component Views
     * @param machines
     * @param type
     */
    createMachineComponentViews(machines: Array<Machine>, type: ViewType) {
        for (let machine of machines) {
            this.createMachineComponentView(machine, type);
        }
    }

    /**
     * Fins a view for the given machine.
     * @param machineId ID of the machine.
     */
    findMachineView(machineId: number): IMachineView {
        return this.machineViews.find(mv => mv.machine.machineId === machineId);
    }

    /**
     * Renders Machine View Content
     * @param {string} machineId
     */
    updateMachineView(machineId: number) {
        let machineView = this.findMachineView(machineId);

        if (machineView === undefined) {
            console.log(`[ARComponents] Machine with id ${machineId} not found and cannot be updated!`);
        } else {
            machineView.update();
        }
    }

    /**
     * Renders All Machine Views Content
     */
    updateAllMachineViews() {
        for (const machineView of this.machineViews) {
            machineView.update();
        }
    }

    /**
     * Removes all machine views.
     */
    removeAllMachineViews() {
        for (let view of this.machineViews) {
            view.detach();
        }
        this.machineViews = [];
    }

    /**
     * Create Machine Component View
     */
    createMachineComponentView(machine: Machine, type: ViewType) {
        let machineView;

        switch (type) {
            case ViewType.Worker:
                machineView = machineFactory.createWorkerMachineView(machine, this.controller.languages, this.assetRegister);
                break;
            case ViewType.Manager:
                machineView = machineFactory.createManagerMachineView(machine, this.controller.languages, this.assetRegister);
                break;
        }

        this.machineViews.push(machineView);
    }

    /**
     * Re-renders time-based information in all views.
     */
    updateTimeInAllViews() {
        for (let machineView of this.machineViews) {
            machineView.updateTime();
        }
    }
}
