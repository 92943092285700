import { Moment } from "moment";
import {IMessage, AuthenticationRequestMessage, AuthenticationResponseMessage, InfoMessage, PongMessage} from "../../../../common/sync/Message";
import {MessageType} from "../../../../common/sync/MessageType";
import { EventEmitter } from "../../EventEmitter";
import * as moment from 'moment';

export declare interface BrowserSocketClient {
    on(event: 'message', listener: (message: IMessage) => void): this;
    on(event: 'error', listener: (error: Error) => void): this;
    on(event: 'close', listener: () => void): this;
}

export class BrowserSocket extends EventEmitter {

    /**
     * Internal ID of this socket.
     */
    readonly id: number;

    /**
     * Determines whether the given socket responded recently.
     */
    private lastContact: Moment;

    /**
     * How long to wait before marking the socket as not alive.
     */
    readonly timeout: number;

    /**
     * The actual connection.
     */
    private socket: WebSocket;

    constructor(id: number, socket: WebSocket, timeout: number) {
        super();

        this.id = id;
        this.socket = socket;
        this.timeout = timeout;
        this.lastContact = moment();

        this.registerEvents();
    }

    private registerEvents() {
        this.socket.onmessage = (m: MessageEvent) => this.onMessage(m.data);
        this.socket.onclose = () => this.onClose();
        this.socket.onerror = e => this.onError(e);
    }

    private onMessage(m: string) {
        this.lastContact = moment();

        try {
            const json = JSON.parse(m);
            const message = <IMessage> json;

            if (message) {
                console.log("[Sync] Received message.", message);
                this.emit('message', message);
            } else {
                console.error('[Sync] Cannot convert incoming message to IMessage!');
            }
            
        } catch (e) {
            console.error('[Sync] Problem with processing incoming message.', e);
        }
    }

    private onClose() {
        console.error(`[Sync] Connection to socket server was closed.`);
        this.emit('close');
    }

    private onError(e: Event) {
        console.error(`[Sync] Socket error.`, e);
        this.emit('error', e);
    }

    /**
     * Returns true if the socket send something recently (the last received message is not older than timeout).
     */
    get isAlive(): boolean {
        const now = moment();
        const allowedPongDelay = 1000; // ms
        return now.diff(this.lastContact) <= this.timeout + allowedPongDelay;
    }

    /**
     * Send a 'pong' string to the client.
     */
    pong() {
        this.send(new PongMessage());
    }
    
    /**
     * Closes the connection.
     */
    terminate() {
        this.socket.close();
        console.error('[Sync] Socket terminated.');
        this.emit('close');
    }

    /**
     * Send the given message to the backend.
     * @param message
     */
    send(message: IMessage) {
        if (this.socket && this.socket.readyState === 1) {
            const json = JSON.stringify(message);
            console.log(`[Sync] Sending a message to the server.`, message);
            this.socket.send(json);
        } else {
            console.error('[Sync] Cannot send a message. Communication channel not ready.');
        }
    }

}