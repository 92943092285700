import { LanguageModelList } from "./LanguageModelList";
import { LanguageModel } from "./LanguageModel";
import { ARController } from "../ARController";
import {singleton} from "tsyringe";

@singleton()
export class Languages {
    languages: Array<LanguageModel>;
    currentLanguage: LanguageModel;
    controller: ARController;

    /**
     * Languages
     * @constructor
     */
    constructor() {
        this.languages = LanguageModelList;
        if (this.languages.length > 0) {
            this.currentLanguage = this.languages[1];
        }
    }

    /**
     * Init Function
     */
    init(ar: ARController) {
        this.controller = ar;
    }

    /**
     * Set Language
     * @param language {LanguageModel} - Language Model
     */
    setLanguage(language: LanguageModel) {
        this.currentLanguage = language;
        this.controller.updateAllViews();
    }

    /**
     * Find and Set Language
     */
    findAndSetLanguage(language: string) {
        let languageModel = this.languages.find(lang => lang.locale === language);
        this.setLanguage(languageModel);
    }
}
