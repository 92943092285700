import {ProjectDto} from "./ProjectDto";
import {WorkerPersonDto} from "./WorkerPersonDto";
import {MachineStateHistoryDto} from "./MachineStateHistoryDto";
import { MachineStateChangeDto } from "./MachineStateChangeDto";

export class MachineDto {
    machineId: number;
    machineName: string;
    worker: WorkerPersonDto;
    projects: Array<ProjectDto>;
    marker: string;
    markerLevel: number;
    efficiency: number;
    stateHistory: MachineStateHistoryDto;
    manufacturingCycleDurationSeconds: number;
    state?: MachineStateChangeDto;
    stateStart?: string;
}
