export enum MessageType {
    info = "INFO",
    authRequest = "AUTHREQUEST",
    authResponse = "AUTHRESPONSE",
    requestAllData = "ALLREQUEST",
    allData = "ALLRESPONSE",
    machineWorker = "UPDATEWORKER",
    machineEfficiency = "UPDATEEFFICIENCY",
    machineState = "UPDATESTATE",
    machineProjects = "UPDATEPROJECTS",
    machineProjectModelUrl = "UPDATEMODELURL",
    debugCameraData = "DEBUGCAMERADATA",
    machineProjectPieces = "UPDATEPIECES",
    machineMarkerLevel = "UPDATEMARKERLEVEL",
    ping = "PING",
    pong = "PONG",
}

export const dataMessageTypes = [
    MessageType.allData,
    MessageType.machineWorker,
    MessageType.machineEfficiency,
    MessageType.machineState,
    MessageType.machineProjects,
    MessageType.machineProjectModelUrl,
    MessageType.machineProjectPieces,
    MessageType.machineMarkerLevel,
]