import {MachineState} from "../model/MachineState";
import {MachineDto} from "../model/MachineDto";
import * as moment from "moment";
import { WorkerPersonDto } from "../model/WorkerPersonDto";

export class FakeData {
    get machines(): Array<MachineDto> {
        let machines = [

            {
                "machineId": 128,
                "machineName": "SM2",
                "worker": {
                    "id": "16",
                    "name": "Hrdlièka Jan",
                    "photoUrl": "https://ar.mediaefekt.cz:8090/worker_photo/16.gif"
                },
                "projects": [
                    {
                        "isCurrent": true,
                        "modelUrl": "https://ar.mediaefekt.cz:8090/model/80348.obj",
                        "name": "K36-001 80348_2110156B",
                        "plannedStart": "2021-03-10T23:00:00.000Z",
                        "plannedEnd": "2021-04-21T23:00:00.000Z",
                        "pieces": 1548,
                        "rejects": 29,
                        "plannedPieces": 3000
                    }
                ],
                "marker": "128",
                "markerLevel": 115,
                "efficiency": 25,
                "stateHistory": {
                    "2021-06-04T22:23:31.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-05T01:12:41.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T01:19:33.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-04T20:49:00.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-06T23:00:06.000Z": {
                        "state": "MALFUNCTION"
                    },
                    "2021-06-06T23:07:03.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-05T00:15:31.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-04T22:46:00.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-05T02:21:40.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-04T23:24:21.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-06T20:49:33.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-06T23:05:13.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-04T21:27:11.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-04T19:38:00.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-05T03:40:51.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T13:14:54.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-04T21:45:20.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-06T23:52:13.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T00:41:13.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T13:57:24.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-06T23:52:03.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-05T03:02:40.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T03:03:25.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-04T17:31:20.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-04T16:55:11.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T00:39:23.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-04T19:13:01.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T02:20:35.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-06T22:21:54.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-05T01:34:40.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-04T16:17:00.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-04T23:37:20.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-06T21:18:43.000Z": {
                        "state": "MALFUNCTION"
                    },
                    "2021-06-06T21:23:23.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-06T21:18:53.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-05T02:59:51.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-04T20:16:11.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T04:02:13.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T03:51:05.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-05T00:34:30.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T03:12:43.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-06T22:01:45.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-04T18:34:50.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-04T18:09:31.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T04:40:25.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T00:30:35.000Z": {
                        "state": "MALFUNCTION"
                    },
                    "2021-06-07T02:25:13.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-05T02:12:51.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T13:53:05.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T01:42:23.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-06T23:45:25.000Z": {
                        "state": "MALFUNCTION"
                    },
                    "2021-06-06T23:51:23.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T14:35:45.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T14:43:24.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T14:21:35.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T14:40:44.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    }
                },
                "manufacturingCycleDurationSeconds": 2301
            },


            {
                "machineId": 58,
                "machineName": "Liza",
                "worker": <WorkerPersonDto>null,
                "projects": [
                    {
                        "isCurrent": true,
                        "modelUrl": "https://ar.mediaefekt.cz:8090/model/7055_17.obj",
                        "name": "K6H 7055/17 bez L_2110277A",
                        "plannedStart": "2021-05-13T23:00:00.000Z",
                        "plannedEnd": "2021-06-17T23:00:00.000Z",
                        "pieces": 12,
                        "rejects": 0,
                        "plannedPieces": 12
                    }
                ],
                "marker": "058",
                "markerLevel": 115,
                "efficiency": 0,
                "stateHistory": {
                    "2021-06-07T08:07:50.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T09:14:22.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T06:48:12.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T08:43:20.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T07:32:40.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T07:24:51.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T05:50:08.000Z": {
                        "state": "SERIZOVANI"
                    },
                    "2021-06-07T06:17:40.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T08:03:31.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T05:06:43.000Z": {
                        "state": "SERIZOVANI"
                    },
                    "2021-06-07T06:17:33.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T05:50:07.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T00:58:48.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T08:38:52.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    },
                    "2021-06-07T06:53:50.000Z": {
                        "state": "BUSY",
                        "isManufacturingCycleStart": true
                    },
                    "2021-06-07T09:17:56.000Z": {
                        "state": "SERIZOVANI"
                    },
                    "2021-06-07T09:18:00.000Z": {
                        "state": "IDLE",
                        "isManufacturingCycleEnd": true
                    }
                },
                "manufacturingCycleDurationSeconds": 0
            },

            {
                machineId: 1,
                machineName: "Hiro",
                worker: {
                    id: "person1",
                    name: "David Prochazka",
                    photoUrl: "/img/david.png"
                },
                projects: [
                    {
                        name: "CNC Part 001",
                        plannedStart: moment().subtract(2, 'hours').toISOString(),
                        plannedEnd: moment().add(1, 'hours').toISOString(),
                        isCurrent: true,
                        pieces: 65,
                        rejects: 40,
                        plannedPieces: 50,
                        modelUrl: "/models/dil_1/model.obj",
                    }
                ],
                marker: "hiro",
                markerLevel: 150,
                efficiency: 30,
                state: {
                    state: MachineState.busy
                },
                stateStart: moment().subtract(30, 'minutes').toISOString(),
                stateHistory: {
                    [moment().subtract(2.3, 'day').toISOString()]: { state: MachineState.malfunction },
                    [moment().subtract(1, 'day').toISOString()]: { state: MachineState.idle },
                    [moment().subtract(5, 'hours').toISOString()]: { state: MachineState.busy, isManufacturingCycleStart: true },
                    [moment().subtract(1, 'hours').toISOString()]: { state: MachineState.maintenance },
                },
                manufacturingCycleDurationSeconds: 7 * 3600
            },
            {
                machineId: 2,
                machineName: "Kanji",
                worker: {
                    id: "person2",
                    name: "Jan Prochazka",
                    photoUrl: null
                },
                projects: [
                    {
                        name: "CNC Part 002",
                        plannedStart: moment().subtract(1, 'hours').toISOString(),
                        plannedEnd: moment().add(2, 'hours').toISOString(),
                        isCurrent: true,
                        pieces: 60,
                        rejects: 20,
                        plannedPieces: 120,
                        modelUrl: "/models/dil_2/model.obj",
                    }
                ],
                marker: "kanji",
                markerLevel: 140,
                efficiency: 80,
                state: {
                    state: MachineState.malfunction
                },
                stateStart: moment().subtract(4, 'hours').toISOString(),
                stateHistory: {
                    [moment().subtract(4, 'days').toISOString()]: {state: MachineState.busy},
                },
                manufacturingCycleDurationSeconds: 180
            }
        ];

        for (let i = 1; i <= 20; i++) {
            machines.push({
                machineId: i + 2,
                machineName: "Marker" + i,
                worker: {
                    id: "person" + (i + 2),
                    name: "Marker " + i,
                    photoUrl: null
                },
                projects: [
                    {
                        name: "CNC Part " + i.toString().padZero(3),
                        plannedStart: moment().subtract(1, 'hours').toISOString(),
                        plannedEnd: moment().add(2, 'hours').toISOString(),
                        isCurrent: true,
                        pieces: 60,
                        rejects: 20,
                        plannedPieces: 120,
                        modelUrl: "/models/dil_" + (i % 2 + 1) + "/model.obj",
                    }
                ],
                marker: i.toString().padZero(3),
                markerLevel: 160,
                efficiency: 80,
                state: {
                    state: MachineState.malfunction
                },
                stateStart: moment().subtract(4, 'hours').toISOString(),
                stateHistory: {
                    [moment().subtract(4, 'days').toISOString()]: {state: MachineState.busy},
                },
                manufacturingCycleDurationSeconds: 180
            });
        }

        return machines;
    }
}
