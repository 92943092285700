import {MachineState} from "./MachineState";
import * as moment from "moment";
import {Moment} from "moment";
import {MachineStateChange} from "./MachineStateChange";
import {config} from "../../config";
import {MachineStateHistoryDto} from "./MachineStateHistoryDto";

export class MachineStateHistory extends Array<MachineStateChange> {
    static fromDto(dtos: MachineStateHistoryDto) {
        let result = new MachineStateHistory();

        Object.keys(dtos).forEach((isoTime: string) => {
            const change = MachineStateChange.fromDto(dtos[isoTime], isoTime);
            result.push(change);
        });

        return result;
    }

    asDto(): MachineStateHistoryDto {
        let result: MachineStateHistoryDto = {};
        this.forEach(change =>
            result[change.time.toISOString()] = {
                state: change.state.toString(),
                isManufacturingCycleStart: change.isManufacturingCycleStart,
                isManufacturingCycleEnd: change.isManufacturingCycleEnd
            }
        );
        return result;
    }

    /**
     * Returns the history sorted by date asc.
     */
    get sorted(): MachineStateHistory {
        return this.sort((a, b) => a.time.valueOf() - b.time.valueOf());
    }

    /**
     * Returns all state changes that occurred in the given range, sorted asc, the oldest first.
     * @param start
     * @param end
     * @param includingLimits Whether to include start and end into comparison. If true, a change equal to start or end is matched.
     */
    getStateChangesBetween(start: Moment, end: Moment, includingLimits: Boolean = true): Array<MachineStateChange> {
        const inclusively = includingLimits ? '[]' : '()';
        return this
            .filter(change =>
                change.time.isBetween(start, end, null, inclusively)
            )
            .sort((a, b) =>
                a.time.valueOf() - b.time.valueOf()
            );
    }

    /**
     * Returns the present state at the given time.
     * It is determined by the closest previous state change.
     * @param time
     */
    getStateAt(time: Moment): MachineState {
        let previousChange: MachineStateChange = null;

        for (let change of this) {
            if (
                change.time.isBefore(time) &&
                (previousChange === null || change.time.isAfter(previousChange.time))
            ) {
                previousChange = change;
            }
        }

        return previousChange === null
            ? MachineState.idle
            : previousChange.state;
    }

    /**
     * Removes all state changes older than the given number of days.
     * @param days
     */
     pruneOldItems(days: number = config.machineStateHistoryDays) {
        const limit = moment().subtract(days, 'days');

        for (let index = this.length - 1; index >= 0; index--) {
            const change = this[index];
            if (change.time.isBefore(limit)) {
                this.splice(index, 1);
            }
        }
    }

    /**
     * Finds the start of the current production cycle.
     * Returns the moment when the current cycle was started.
     * In case the cycle is not running, returns null.
     */
    findStartOfCurrentCycle(): Moment {
        for (let i = this.length - 1; i >= 0; i--) {
            if (this[i].isManufacturingCycleStart) {
                return this[i].time;
            } else if (this[i].isManufacturingCycleEnd) {
                return null;
            }
        }
        return null;
    }

    /**
     * Returns how much time of the current production cycle has already passed.
     * Only busy periods are counted (malfunction and maintenance periods are excluded)
     * @return {number} milliseconds from the start of the current cycle, null in case no cycle is running
     */
    calculateElapsedTimeInCurrentCycle(): number {
        const cycleStart = this.findStartOfCurrentCycle();

        if (cycleStart === null) {
            return null;
        }

        const changesDuringCycle = this.getStateChangesBetween(cycleStart, moment(), true);
        let elapsedTime = 0;

        changesDuringCycle.forEach((change, i) => {
            if (change.state == MachineState.busy) {
                const nextChange = i < changesDuringCycle.length - 1
                    ? changesDuringCycle[i + 1].time
                    : moment();
                elapsedTime += nextChange.diff(change.time);
            }
        });

        return elapsedTime;
    }
}