import {ServerDataProvider} from "./ServerDataProvider";
import {ARController} from "../../ARController";
import {FakeDataProvider} from "./FakeDataProvider";

class DataProviderFactory {
    /**
     * Create Data Provider Factory
     * @param {boolean} enableSync 
     * @returns {ServerDataProvider | FakeDataProvider}
     */
    create(enableSync: boolean = true): ServerDataProvider | FakeDataProvider {
        if (enableSync) {
            return new ServerDataProvider();
        } else {
            return new FakeDataProvider();
        }
    }
}

export const dataProviderFactory = new DataProviderFactory();