import {Machine} from "../../../common/model/Machine";
import {Languages} from "../../languages/Languages";
import * as $ from "jquery";
import {Asset} from "./Models/Asset";
import {AssetType} from "./Models/AssetType";
import {AssetRegister} from "./Models/AssetRegister";
import {URLParams} from "../../services/URLParams";
import {config} from "../../../config";
import { resourceUrl } from "../../services/ResourceUrl";
import { FreshElement } from "./Models/FreshElement";

export interface IMachineView {
    /**
     * Machine Data
     */
    machine: Machine;

    /**
     * Languages
     */
    languages: Languages;

    /**
     * Attaches all subcomponents to the scene.
     */
    attach(): void;

    detach(): void;

    show(): void;

    hide(): void;

    /**
     * Update Machine View Data
     */
    update(): void;

    updateTime(): void;

    /**
     * Get HTML String
     * @returns {string} HTML
     */
    getHtmlString(): string;

    /**
     * Get Inner HTML String
     * @returns {string} HTML
     */
    getInnerHtmlString(): string;

    /**
     * Fakes marker detection = displays the machine view immediately on a position in front of the camera.
     */
    triggerMarkerDetection(): void;
}


export abstract class MachineView {

    /**
     * Is this machine view rendered?
     * This value is false, if a placeholder is rednered instead of the machine view content.
     */
    protected isRendered = false;

    public get isContentRendered() {
        return this.isRendered;
    }

    /**
     * Machine Data
     */
    machine: Machine;

    /**
     * Languages
     */
    languages: Languages;

    /**
     * An array of elements that must be updated periodically.
     */
    private freshElements = new Array<FreshElement>();

    /**
     * Returns the root element of this machine view.
     */
    get rootElement(): JQuery {
        return $(`#machine-${this.machine.marker}`);
    }

    /**
     * Ensures that the given element is updated at least each maxDecay ms.
     * @param element The target element - given as a callback function returning the updated element's html as string.
     * @param maxDecay Milliseconds. No view will be older.
     */
    protected keepFresh(element: Function, maxDecay: number) {
        const freshElement = new FreshElement(this, element, maxDecay);
        this.freshElements.push(freshElement);
    }

    /**
     * Updates all subviews that are based on current time.
     */
    updateTime() {
        this.freshElements.forEach(e => e.updateIfNeeded());
    }

    /**
     * Updates the whole machine view.
     * Should be specified in subclasses.
     * Do not forget to call super.update() in subclasses!
     */
    update() {
        this.freshElements.forEach(el => el.setLastUpdatedNow());
    }

    /**
     * Stops time updates of all elements.
     */
    stopKeepingFresh() {
        this.freshElements = [];
    }

    /**
     * Removes this machine view from the scene.
     */
    detachView() {
        this.stopKeepingFresh();
        this.rootElement.remove();
        this.isRendered = false;
    }
}

export class MachineView3D extends MachineView implements IMachineView {
    
    /**
     * Asset Register
     */
    assetRegister: AssetRegister;

    /**
     * Machine View 3D
     * @param machine {Machine} - View Data
     * @param languages {Languages} – Languages
     * @constructor
     */
    constructor(machine: Machine, languages: Languages, assetRegister: AssetRegister) {
        super();
        this.languages = languages;
        this.machine = machine;
        this.assetRegister = assetRegister;
    }

    /**
     * Attaches all subcomponents to the scene.
     */
    attach() {
        this.attachMarker();
        this.attachAssets();
        this.attachView();
    }

    /**
     * Removes all subcomponents from the scene.
     */
    detach() {
        this.detachMarker();
        this.detachView();
    }

    /**
     * Displays this view.
     */
    show() {
        this.rootElement.attr('visible', 'true');
        this.updateIfNeeded();
    }

    /**
     * Hides this machine view.
     */
    hide() {
        this.rootElement.attr('visible', 'false');
        this.updateIfNeeded();
    }

    /**
     * True if the machine view is visible.
     */
    get isVisible(): Boolean {
        return this.rootElement.attr('visible').toString() === 'true';
    }

    /**
     * Updates the view if the content is rednered but the view is hidden or vice versa.
     */
    updateIfNeeded() {
        if ((this.isVisible && !this.isRendered) || (!this.isVisible && this.isRendered)) {
            this.update();
        }
    }

    /**
     * Re-renders all content of this machine view.
     */
    update() {
        if (this.isVisible) {
            this.isRendered = true;
            this.rootElement.html(this.getInnerHtmlString());
        } else {
            this.isRendered = false;
            this.rootElement.html(this.getPlaceholder());
        }

        super.update();
    }

    /**
     * Registers assets to the scene.
     */
    protected attachAssets() {
        this.assetRegister.register(this.getAssets(), this.machine.machineId);
    }

    /**
     * Attach this machine view to the scene.
     */
    protected attachView() {
        const $scene = (URLParams.IsGoogleGlass) ? $('#html-scene')[0] : $('#scene')[0];
        const machineView = $(this.getHtmlString())[0];
        machineView.addEventListener('updateIfNeeded', () => this.updateIfNeeded());
        $scene.appendChild(machineView);
    }

    /** 
     * Returns the marker element associated to this machine view.
     */
    get markerElement(): JQuery {
        return $("#marker-" + this.machine.marker.toString());
    }

    /**
     * Attach Marker HTML
     */
    protected attachMarker() {
        if (this.markerElement.length === 0) {
            const marker = $(this.getMarkerHtmlString())[0];
            const el = $('#markers')[0];
            el.appendChild(marker);
        }
    }

    /**
     * Removes marker from HTML
     */
    protected detachMarker() {
        // TODO bug cannot remove marker from DOM without breaking further AR detection - https://trello.com/c/CtcN89TP/57-bug-major-nelze-vymenit-markery
        // this.markerElement.remove();
    }

    /**
     * Get Marker HTML String
     * @returns {string}
     */
    protected getMarkerHtmlString(): string {
        const barcodeValue = parseInt(this.machine.marker);

        if (isNaN(barcodeValue)) {
            return `<a-marker id="marker-${this.machine.marker.toString()}" preset="custom" type="pattern" url="markers/${this.machine.marker.toString()}.patt" smooth="true" markerdetection></a-marker>`;
        } else {
            return `<a-marker id="marker-${this.machine.marker.toString()}" preset="custom" type="barcode" value="${barcodeValue}" smooth="true" markerdetection></a-marker>`;
        }
    }

    /**
     * Fakes marker detection = displays the machine view immediately on a position in front of the camera.
     */
    public triggerMarkerDetection() {
        this.markerElement[0].dispatchEvent(new Event("markerFound"));
    }

    /**
     * Get HTML String
     * @returns {string} HTML
     */
    public getHtmlString(): string {
        return `
        <a-entity rotation="0 0 0" scale="0.45 0.45 0.45" id="machine-${this.machine.marker}" machine-id="${this.machine.machineId}" class="machine" visible="false" follow-me-delay>
            <a-entity position="0 1 0">
                ${this.getPlaceholder()}
            </a-entity>
        </a-entity>`;
    }

    getPlaceholder(): string {
        return `<a-entity text="width: 20; color: ${config.colors.primary}; value: ${this.languages.currentLanguage.nacitani} ${this.machine.machineName}...; align: center; anchor: align; font: ${config.fonts.bold}; negate: false"></a-entity>`;
    }

    /**
     * Get Inner HTML String
     * @returns {string} HTML
     */
    getInnerHtmlString(): string {
        return '';
    }

    /**
     * Get Assets
     * @returns {Array<string>}
     */
    protected getAssets(): Array<Asset> {
        return [
            new Asset('busy-state', AssetType.image, "/img/Running.png"),
            new Asset('idle-state', AssetType.image, "/img/Steady.png"),
            new Asset('maintenance-state', AssetType.image, "/img/Maintenance.png"),
            new Asset('malfunction-state', AssetType.image, "/img/Malfunction.png"),
            new Asset('undefined-state', AssetType.image, "/img/Undefined.png"),
            new Asset('adjustment-state', AssetType.image, "/img/Adjustment.png"),
            new Asset('waitingforcontrol-state', AssetType.image, "/img/WaitingForControl.png"),
            new Asset('profile-picture-default', AssetType.image, "/img/avatar-icon.png"),
        ];
    }

    /**
     * Returns the URL of the current worker's profile photo.
     * In case the worker has no photo, a default avatar is returned.
     */
    protected get profilePictureSrc(): string {
        if (!this.machine.hasWorker) {
            return null;
        } else if (!this.machine.worker.photoUrl) {
            return '#profile-picture-default';
        } else {
            return resourceUrl(this.machine.worker.photoUrl);
        }
    }

}

export class MachineView2D extends MachineView implements IMachineView {

    /**
     * Machine Data
     */
    machine: Machine;

    /**
     * Languages
     */
    languages: Languages;

    /**
     * Asset Register
     */
    assetRegister: AssetRegister;

    /**
     * Machine View 2D
     * @param machine {Machine} - View Data
     * @param languages {Languages} – Languages
     * @param assetRegister
     * @constructor
     */
    constructor(machine: Machine, languages: Languages, assetRegister: AssetRegister) {
        super();
        this.languages = languages;
        this.machine = machine;
        this.assetRegister = assetRegister;
    }

    /**
     * Attaches all subcomponents to the scene.
     */
    attach() {
        this.attachMarker();
        this.attachView();
    }

    /**
     * Removes all subcomponents from the scene.
     */
    detach() {
        this.detachMarker();
        this.detachView();
    }

    /**
     * Re-renders all content of this machine view.
     */
    update() {
        this.rootElement.html(this.getInnerHtmlString());
        super.update();
    }

    /**
     * Attach this machine view to the scene.
     */
    protected attachView() {
        const $scene = (URLParams.IsGoogleGlass) ? $('#html-scene')[0] : $('#scene')[0];
        $scene.appendChild($(this.getHtmlString())[0]);
        this.isRendered = true;
    }

    /** 
     * Returns the marker element associated to this machine view.
     */
    get markerElement(): JQuery {
        return $("#marker-" + this.machine.marker.toString());
    }

    /**
     * Attach Marker HTML
     */
    protected attachMarker() {
        if (this.markerElement.length === 0) {
            $('#scene')[0].appendChild($(this.getMarkerHtmlString())[0]);
        }
    }

    /**
     * Removes marker from HTML
     */
    protected detachMarker() {
        // TODO bug cannot remove marker from DOM without breaking further AR detection - https://trello.com/c/CtcN89TP/57-bug-major-nelze-vymenit-markery
        // this.markerElement.remove();
    }

    /**
     * Fakes marker detection = displays the machine view immediately on a position in front of the camera.
     */
    public triggerMarkerDetection() {
        this.markerElement[0].dispatchEvent(new Event("markerFound"));
    }

    /**
     * Get Marker HTML String
     * @returns {string}
     */
    protected getMarkerHtmlString(): string {
        const barcodeValue = parseInt(this.machine.marker);

        if (isNaN(barcodeValue)) {
            return `<a-marker id="marker-${this.machine.marker.toString()}" preset="custom" type="pattern" url="markers/${this.machine.marker.toString()}.patt" markerdetection></a-marker>`;
        } else {
            return `<a-marker id="marker-${this.machine.marker.toString()}" preset="custom" type="barcode" value="${barcodeValue}" markerdetection></a-marker>`;
        }
    }

    /**
     * Get Assets
     * @returns {Array<string>}
     */
    public getAssets(): Array<Asset> {
        let assets = [
            new Asset('busy-state', AssetType.image, "/img/Running.png"),
            new Asset('idle-state', AssetType.image, "/img/Steady.png"),
            new Asset('maintenance-state', AssetType.image, "/img/Maintenance.png"),
            new Asset('malfunction-state', AssetType.image, "/img/Malfunction.png"),
            new Asset('undefined-state', AssetType.image, "/img/Undefined.png"),
            new Asset('adjustment-state', AssetType.image, "/img/Adjustment.png"),
            new Asset('waitingforcontrol-state', AssetType.image, "/img/WaitingForControl.png"),
            new Asset('profile-picture-default', AssetType.image, "/img/avatar-icon.png"),
        ];

        return assets;
    }

    /**
     * Registers assets to the scene.
     */
    protected attachAssets() {
        this.assetRegister.register(this.getAssets(), this.machine.machineId);
    }

    /**
     * Get HTML String
     * @returns {string}
     */
    getHtmlString(): string {
        return `<div id="machine-${this.machine.marker}" machine-id="${this.machine.machineId}" class="machine row" style="display: none">${this.getInnerHtmlString()}</div>`;
    }

    /**
     * Get Inner HTML String
     * @returns {string}
     */
    getInnerHtmlString(): string {
        return '';
    }

    /**
     * Displays this view immediately regardless of whether the associated marker is recognized.
     */
    show() {
        this.rootElement.css('display', 'block');
        this.update();
    }

    /**
     * Hides this machine view immediately.
     */
    hide() {
        this.rootElement.css('display', 'none');
        this.update();
    }

    /**
     * Returns the URL of the current worker's profile photo.
     * In case the worker has no photo, a default avatar is returned.
     */
    protected get profilePictureSrc(): string {
        if (!this.machine.hasWorker) {
            return null;
        } else if (!this.machine.worker.photoUrl) {
            const asset = this.getAssets().find(a => a.id === 'profile-picture-default');
            return asset.src;
        } else {
            return resourceUrl(this.machine.worker.photoUrl);
        }
    }
}