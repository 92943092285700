import { LanguageModel } from "../LanguageModel";

const czl = new LanguageModel();

czl.name = "Čeština";
czl.locale = "cs";
czl.iconSource = "/img/czech.png";

czl.probihaVyroba = "Probíha výroba";
czl.porucha = "Porucha";
czl.vUdrzbe = "V údržbě";
czl.vNecinnosti = "V nečinnosti";
czl.neznamy = "Neznámý stav";
czl.serizovani = "Seřizování";
czl.cekaniNaKontrolu = "Čekání na kontrolu";

czl.prihlasen = "Přihlášen:";
czl.prubehVyroby = "Výroba na stroji";
czl.vyrobenoKs = "Vyrobeno (ks):";
czl.caseDoKonceCyklu = "Čas do konce cyklu:";
czl.produktivitaStroje = "Produktivita:";
czl.nacitani = "Načítání";
czl.odpojen = "ODPOJEN";

export const czechLanguageModel = czl;
