import {config} from "../../config";

export enum MachineState {
    busy = "BUSY",
    idle = "IDLE",
    maintenance = "MAINTENANCE",
    malfunction = "MALFUNCTION",
    adjustment = "SERIZOVANI",
    waitingForControl = "CNK",
}

export namespace MachineState {
    export function colorOf(state: MachineState) {
        switch (state) {
            case MachineState.busy:
                return config.colors.busy;
            case MachineState.idle:
                return config.colors.idle;
            case MachineState.maintenance:
                return config.colors.maintenance;
            case MachineState.malfunction:
                return config.colors.malfunction;
            case MachineState.adjustment:
                return config.colors.adjustment;
            case MachineState.waitingForControl:
                return config.colors.waitingForControl;
            default:
                return `${config.colors.primary}; opacity: 0.5`;
        }
    }
}
