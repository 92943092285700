import {removeDiacritics} from "../utils";
import {WorkerPersonDto} from "../model/WorkerPersonDto";

export class WorkerPerson {
    /**
     * ID of Worker
     */
    id: string;

    /**
     * Name of Worker
     */
    name: string;

    /**
     * Profile Picture URL
     */
    photoUrl?: string;

    /**
     * Worker
     * @constructor
     */
    constructor(id: string, name: string, photoUrl?: string) {
        this.id = id;
        this.name = name;
        this.photoUrl = photoUrl;
    }

    static fromDto(dto: WorkerPersonDto) {
        return new WorkerPerson(
            dto.id,
            dto.name,
            dto.photoUrl
        );
    }

    asDto(): WorkerPersonDto {
        let dto = new WorkerPersonDto();
        dto.id = this.id;
        dto.name = this.name;
        dto.photoUrl = this.photoUrl;
        return dto;
    }

    get sanitizedName(): string {
        return removeDiacritics(this.name);
    }
}