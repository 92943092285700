import * as $ from 'jquery';

export abstract class Scene {


    protected sceneContent: JQuery;
    protected arJsConfig: string;

    constructor(arJsConfig: string) {
        this.arJsConfig = arJsConfig;
    }

    attach() {
        const html = $(this.getHtmlString())[0];
        document.querySelector('body').appendChild(html);
    }

    protected getHtmlString(): string {

        // override in subclasses

        return "";
    }

}