import { Application } from "./Application";
import MockDate from 'mockdate';

window.onload = () => {

    // DEBUG: mock fake date time for debugging
    // MockDate.set("June 7, 2021 17:00:00");

    // start the app
    const app = new Application();
    app.init();

};