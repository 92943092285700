import {Project} from "./Project";
import {MachineDto} from "./MachineDto";
import {MachineState} from "./MachineState";
import {WorkerPerson} from "./WorkerPerson";
import {MachineStateHistory} from "./MachineStateHistory";
import {MachineStateChange} from "./MachineStateChange";
import {Shift} from "./Shift";
import moment = require("moment");
import { MachineSimpleDto } from "./MachineSimpleDto";

export class Machine {
    /**
     * Machine ID
     */
    readonly machineId: number;

    /**
     * Machine Name
     */
    readonly machineName: string;

    /**
     * Machine Marker File Name (for detection)
     */
    marker: string;

    /**
     * Machine Marker Level in centimeters (the position of the marker above the ground)
     */
    markerLevel: number;

    /**
     * All Jobs on Machine
     */
    projects: Array<Project> = new Array<Project>();

    /**
     * Worker Person
     */
    worker: WorkerPerson;

    /**
     * History of Machine States
     */
    stateHistory: MachineStateHistory = new MachineStateHistory();

    /**
     * Number of seconds to produce one item.
     * This time does not include malfunction and maintenance breaks.
     */
    manufacturingCycleDurationSeconds: number;

    /**
     * Number of milliseconds to produce one item.
     * This time does not include malfunction and maintenance breaks.
     */
    get manufacturingCycleDurationMilliseconds(): number {
        return this.manufacturingCycleDurationSeconds * 1000;
    }

    /**
     * The last known state of the machine.
     */
    get currentState(): MachineState {
        const states = this.stateHistory.sorted;
        const state = states[states.length - 1];
        return state !== undefined ? state.state : null;
    }

    /**
     * Efficiency of the machine since the Big Bang.
     */
    efficiency: number;

    /**
     * Machine
     * @constructor
     */
    constructor(machineId: number, machineName: string, marker: string, markerLevel: number, projects: Array<Project>, worker: WorkerPerson, stateHistory: MachineStateHistory, efficiency: number, manufacturingCycleDurationSeconds: number) {
        this.machineId = machineId;
        this.machineName = machineName;
        this.marker = marker;
        this.markerLevel = markerLevel;
        this.projects = projects;
        this.worker = worker;
        this.efficiency = efficiency;
        this.stateHistory = stateHistory;
        this.manufacturingCycleDurationSeconds = manufacturingCycleDurationSeconds;
    }

    static fromDto(dto: MachineDto) {
        const projects = dto.projects.map(p => Project.fromDto(p));
        const worker = dto.worker === null ? null : WorkerPerson.fromDto(dto.worker);
        const stateHistory = MachineStateHistory.fromDto(dto.stateHistory);

        // push the current state to state history to have everything there
        if (dto.state && dto.stateStart) {
            const change = MachineStateChange.fromDto(dto.state, dto.stateStart);
            stateHistory.push(change);
        }

        return new Machine(
            dto.machineId,
            dto.machineName,
            dto.marker,
            dto.markerLevel,
            projects,
            worker,
            stateHistory,
            dto.efficiency,
            dto.manufacturingCycleDurationSeconds
        );
    }

    asDto(): MachineDto {
        let dto = new MachineDto();
        dto.machineId = this.machineId;
        dto.machineName = this.machineName;
        dto.worker = this.worker.asDto();
        dto.projects = this.projects.map(p => p.asDto());
        dto.marker = this.marker;
        dto.markerLevel = this.markerLevel;
        dto.efficiency = this.efficiency;
        dto.stateHistory = this.stateHistory.asDto();
        dto.manufacturingCycleDurationSeconds = this.manufacturingCycleDurationSeconds;
        return dto;
    }

    asSimpleDto(): MachineSimpleDto {
        let dto = new MachineSimpleDto();
        dto.machineId = this.machineId;
        dto.machineName = this.machineName;
        dto.marker = this.marker;
        dto.markerLevel = this.markerLevel;
        dto.efficiency = this.efficiency;
        dto.state = this.currentState;
        dto.remainingCycleTime = this.remainingCycleTime;
        dto.manufacturingCycleDurationMilliseconds = this.manufacturingCycleDurationMilliseconds;
        dto.workerId = this.hasWorker ? this.worker.id : null;
        dto.workerName = this.hasWorker ? this.worker.name : null;
        dto.workerPhotoUrl = this.hasWorker ? this.worker.photoUrl : null;
        dto.projectName = this.currentProject ? this.currentProject.name : null;
        dto.projectModelUrl = this.currentProject ? this.currentProject.modelUrl : null;
        dto.projectProducedPieces = this.currentProject ? this.currentProject.pieces - this.currentProject.rejects : null;
        dto.projectPlannedPieces = this.currentProject ? this.currentProject.plannedPieces : null;
        return dto;
    }

    /**
     * Returns the currently active project
     * @returns {Project}
     */
    get currentProject(): Project | null {
        const project = this.projects.find(job => job.isCurrent);
        return project !== undefined ? project : null;
    }

    /**
     * Returns projects that are not active and ended
     * @returns {Array<Project>}
     */
    get pastProjects(): Array<Project> {
        return this.projects.filter(job => (job.plannedEnd <= new Date()));
    }

    /**
     * Returns projects that are not active and started yet
     * @returns {Array<Project>}
     */
    get futureProjects(): Array<Project> {
        return this.projects.filter(job => (job.plannedEnd >= new Date()));
    }

    /**
     * Get state changes during the given shift.
     * Includes pseudo change at the beginning and end of the shift.
     * This pseudo change depicts the borders of the shift.
     * @param shift
     */
    getStateChangesDuringShift(shift: Shift): Array<MachineStateChange> {
        let stateChanges = this.stateHistory.getStateChangesBetween(shift.start, shift.end);
        const initialState = this.stateHistory.getStateAt(shift.start);
        const now = moment();

        // no changes during this shift
        if (stateChanges.length === 0) {
            if (shift.start.isAfter(now)) {
                return [];
            } else {
                const end = now.isBefore(shift.end) ? now : shift.end;
                return [
                    new MachineStateChange(shift.start, initialState),
                    new MachineStateChange(end, initialState)
                ];
            }
        }

        // add border at the shift start
        const firstChange = stateChanges[0];
        if (firstChange.time.isSame(shift.start) === false) {
            stateChanges.unshift(
                new MachineStateChange(shift.start, initialState)
            );
        }

        // add border at the shift end (or current time if shift not ended)
        const end = now.isBefore(shift.end) ? now : shift.end;
        const lastChange = stateChanges[stateChanges.length - 1];
        if (lastChange.time.isBefore(end)) {
            stateChanges.push(
                new MachineStateChange(end, lastChange.state)
            );
        }

        return stateChanges;
    }

    /**
     * Returns how much time is needed to finish the current production cycle.
     * Malfunction and maintenance periods are not counted.
     * The returned time is always >= 0.
     * @return {number} milliseconds to the end of the cycle, null in case no cycle is running
     */
    get remainingCycleTime(): number {
        const elapsedTime = this.stateHistory.calculateElapsedTimeInCurrentCycle();

        if (elapsedTime === null) {
            return null;
        } else {
            const diff = this.manufacturingCycleDurationMilliseconds - elapsedTime;
            return Math.max(0, diff);
        }
    }

    /**
     * Returns how much of the current production cycle has passed.
     * Malfunction and maintenance periods are not counted.
     * The returned value is on interval <0;100>.
     * @return {number} percents of the current cycle period, 0 if no cycle is running
     */
    get elapsedCyclePercentage(): number {
        const elapsedTime = this.stateHistory.calculateElapsedTimeInCurrentCycle();

        if (elapsedTime === null) {
            return 0;
        } else {
            const percentage = 100 * elapsedTime / this.manufacturingCycleDurationMilliseconds;
            return Math.min(100, Math.max(0, percentage));
        }
    }

    get hasWorker(): boolean {
        return this.worker && this.worker.id != "-1";
    }

}
