import { AframeComponent } from "./AframeComponent";
import * as $ from "jquery";
import {config} from "../../../config";

/// Displays a loading text before an image is loaded.
// TODO: edit aframe-gif-shader lib to dispatch event on load
export const ImageLoadingText = new AframeComponent("image-loading-text", {
    loadingIndicator: HTMLElement,
    schema: {
        type: 'string',
        default: 1
    },
    init: function () {
        // this.addLoadingIndicator();
        // this.el.addEventListener('materialtextureloaded', () => this.hideLoadingIndicator());
    },
    addLoadingIndicator: function() {
        const loadingText = this.data;
        const parent = this.el.parentElement;
        const position = this.el.object3D.position;
        this.loadingIndicator = $(`<a-entity 
                position="${position.x} ${position.y} ${position.z}"
                text="width: 3; color: ${config.colors.textGray}; value: ${loadingText}; align: center; anchor: align; font: ${config.fonts.bold}; negate: false;"></a-entity>`)[0];
        parent.appendChild(this.loadingIndicator);
    },
    hideLoadingIndicator: function() {
        this.loadingIndicator.object3D.visible = false;
    },
});