import { MachineView3D, MachineView2D } from "./MachineView";
import {Elements} from "./Elements";
import {config} from "../../../config";
import { resourceUrl } from "../../services/ResourceUrl";

export class ManagerMachineView3D extends MachineView3D {

    /**
     * Get Inner HTML String
     * @returns {string}
     */
    getInnerHtmlString(): string {
        return (
            this.getProductionInfo() +
            this.getProductionCycleInfo() +
            this.getEfficiencyInfo() +
            this.getMachineState() +
            this.getWorkerProfile() +
            this.getProductionGraph()
        );
    }

    attachView() {
        super.attachView();
        this.keepFresh(() => this.getProductionCycleInfo(), config.refreshTimeInViewsInterval);
        this.keepFresh(() => this.getProductionGraph(), config.refreshProductionGraphInterval);
    }

    public getProductionInfo() {
        return Elements.productionInfo(this.machine.currentProject, 0, this.languages.currentLanguage);
    }

    public getProductionCycleInfo() {
        return Elements.productionCycleInfo(this.machine.remainingCycleTime, this.machine.elapsedCyclePercentage, 0, this.languages.currentLanguage);
    }

    public getEfficiencyInfo() {
        return Elements.efficiencyInfo(this.machine.efficiency, 0, this.languages.currentLanguage);
    }

    /**
     * Get Machine State
     * @returns {string}
     */
    public getMachineState(): string {
        const machineState = this.languages.currentLanguage.getLocalizedMachineState(this.machine.currentState);
        const project = this.machine.currentProject;
        const projectNumber = project ? project.productNumber : "-";
        const model = project ? `${resourceUrl(project.modelUrl)}` : '';
        
        const modelHtml = model === '' ? '' : `
            <!-- A rotating model of the produced part with different lights to emphasize the object's structure -->
            <a-entity position="0 -0.54 0" rotation="0 0 0" animation="property: rotation.y; to: 360; dur: 5000; easing: linear; loop: true;" model-loading-text="${this.languages.currentLanguage.nacitani}...">
                <!-- A weak ambient light to avoid true-black shadows -->
                <a-light type="ambient" intensity="0.2"></a-light>
                <!-- A spot light attached to the top right front of the object -->
                <a-light type="spot" angle="65" intensity="1" penumbra="0.45" position="1.4 1 1.4"></a-light>
                <!-- A spot light attached to the right of the object -->
                <a-light type="spot" angle="65" intensity="0.3" penumbra="0.45" position="1.4 0 0" rotation="0 90 0"></a-light>
                <!-- A spot light attached to the bottom left back of the object -->
                <a-light type="spot" angle="65" intensity="1" penumbra="0.45" position="-1.4 -1 -1.4" rotation="0 180 0"></a-light>
                <!-- A spot light attached to the left of the object -->
                <a-light type="spot" angle="65" intensity="0.3" penumbra="0.45" position="-1.4 0 0" rotation="0 -90 0"></a-light>
                <!-- 3D model object -->
                <a-obj-model position="0 0 0" rotation="0 0 0" src="${model}" normalized-size="1.2 0.8 1.2"></a-obj-model>
            </a-entity>
        `;

        return `
        <a-entity id="machine-state" position="-1 -0.4 0" rotation="0 0 0">
            <a-entity position="0 0.12 0" text="width: 2; color: ${config.colors.primary}; value: ${machineState}; align: center; anchor: align; font: ${config.fonts.primary}; negate: false;"></a-entity>
            <a-entity position="0 0 0" text="width: 2; color: ${config.colors.primary}; value: ${projectNumber}; align: center; anchor: align; font: ${config.fonts.bold}; negate: false;"></a-entity>
            ${modelHtml}
        </a-entity>
        `;
    }

    /**
     * Get Worker Profile
     * @returns {string}
     */
    public getWorkerProfile(): string {
        const language = this.languages.currentLanguage;
        let workerName = "-";

        if (this.machine.hasWorker) {
            workerName = this.machine.worker.sanitizedName;
        }

        return `
        <a-entity id="worker-profile" position="1 -0.4 0" rotation="0 0 0">
            <a-entity position="0 0.12 0" text="width: 2; color: ${config.colors.primary}; value: ${this.languages.currentLanguage.prihlasen}; align: center; anchor: align; font: ${config.fonts.primary}; negate: false;"></a-entity>
            <a-entity position="0 0 0" text="width: 2; color: ${config.colors.primary}; value: ${workerName}; align: center; anchor: align; font: ${config.fonts.bold}; negate: false;"></a-entity>
            ${Elements.profilePhoto(this.profilePictureSrc, "0 -0.58 0", 0.9, 0.9, language)}
        </a-entity>
        `;
    }

    public getProductionGraph() {
        return Elements.productionGraph(this.machine, 1, -2.7, this.languages.currentLanguage);
    }

}

export class ManagerMachineView2D extends MachineView2D {
    /**
     * Get Inner HTML String
     * @returns {string}
     */
    getInnerHtmlString(): string { return ""; }
}

