import { AframeComponent } from "./AframeComponent";
import { THREE } from "aframe";
import { container } from "tsyringe";
import { ARController } from "../../ARController";
import {config} from "../../../config";

export const FollowMeWithDelayComponent = new AframeComponent("follow-me-delay", {
    init: function () {
        this.targetPosition = document.querySelector('#targetPosition');
        this.cameraEl = document.querySelector('#camera');

        const machineId = parseInt(this.el.getAttribute("machine-id"));
        this.ar = container.resolve(ARController);

        this.machineData = this.ar.data.findMachine(machineId);
        this.worldPos = new THREE.Vector3();
        this.obj = this.el.object3D;
        this.camera = this.cameraEl.object3D;
    },
    tick: function () {
        // do nothing if this machine view is not currently displayed
        if (!this.obj.visible) {
            return;
        }

        // read current FOV target
        this.worldPos.setFromMatrixPosition(this.targetPosition.object3D.matrixWorld);

        // calculate the distance from FOV target to the current view position
        const diffX = Math.abs(this.obj.position.x - this.worldPos.x);
        const diffZ = Math.abs(this.obj.position.z - this.worldPos.z);

        // if the distance reaches the threshold, start animating obejct's postion towards FOV target
        if (diffX > config.followAnimationThreshold.x || diffZ > config.followAnimationThreshold.z) {
            const target = this.worldPos.clone();
            target.y = this.machineData.markerLevel / 100; // View position above the ground
            const direction = this.obj.worldToLocal(target);
            this.obj.translateOnAxis(direction, config.followAnimationSpeed);

            // update rotation to have the view always rotated towards the head
            const rotY = Math.atan2((this.camera.position.x - this.obj.position.x), (this.camera.position.z - this.obj.position.z));
            this.obj.rotation.y = rotY;
        }
    },
});
