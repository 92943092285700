import { EventEmitter } from "../../services/EventEmitter";
import * as $ from 'jquery';

export declare interface Button {
    on(event: 'click', listener: () => void): this;
}

export class Button extends EventEmitter {

    private text = "";
    private icon = "";
    private className = "";

    private active = false;

    private element: HTMLElement;

    constructor(text: string, icon: string, className: string = "") {
        super();

        this.className = className;
        this.text = text;
        this.icon = icon;
    }

    attachTo(el: HTMLElement) {
        this.element = $(this.htmlString)[0];
        this.element.onclick = () => this.onClick();

        el.appendChild(this.element);
    }

    protected get htmlString(): string {
        return `
        <div class="button ${this.className}">
            ${this.icon ? this.icon : ''} 
            ${this.text}
        </div>
        `;
    }

    get isActive(): boolean {
        return this.active;
    }

    set isActive(active: boolean) {
        if (active) {
            this.element.classList.add('active');
        } else {
            this.element.classList.remove('active');
        }
        this.active = active;
    }

    protected onClick() {
        this.emit('click');
    }

}