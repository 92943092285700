import {Data} from "../../common/sync/Data";
import * as $ from "jquery";
import {AssetRegister} from "../components/gui/Models/AssetRegister";
import {container, singleton} from "tsyringe";
import {config} from "../../config";
import {ARController} from "../ARController";
import { globalStyles } from "../style";
import { CSS } from "./CSS";

@singleton()
export class Preloader {

    private assetRegister = container.resolve(AssetRegister);
    private controller = container.resolve(ARController);
    private css = container.resolve(CSS);

    loadAll(isGoogleGlass: boolean): Promise<any> {
        return isGoogleGlass ? this.loadAllForGoogleGlass() : this.loadAllForAR();
    }
 
    loadAllForAR(): Promise<any> {
        return Promise.all([
            this.waitForCSSFonts(),
            this.waitFor3DFonts(),
            this.waitForARCamera(),
            new Promise(resolve => {
                this.waitForInitialData().then(() =>
                    this.waitForMachineViews().then(() =>
                        this.waitFor3DAssets().then(resolve)
                    )
                )
            }),
        ]).then(() => 
            console.log('everything loaded')
        );
    }

    loadAllForGoogleGlass(): Promise<any> {
        return Promise.all([
            this.waitForCSSFonts(),
            this.waitForARCamera(),
            this.waitForInitialData(),
        ]).then(() => 
            console.log('everything loaded')
        );
    }

    waitForCSSFonts(): Promise<any> {
        // extract all font URLs from main CSS stylesheet
        const fontUrlRegex = new RegExp("@font-face +{[^}]+url\\(['\"]?([^)'\"]+)['\"]?\\)", "gi");

        const allStyles = this.css.attachedStyles;
        let fontPromises: Array<Promise<any>> = [];
        let match;

        while (match = fontUrlRegex.exec(allStyles)) {
            const url = match[1];
            fontPromises.push(fetch(url));
        }

        return Promise.all(fontPromises).then(() =>
            console.log('css fonts loaded')
        );
    }

    waitForInitialData(): Promise<any> {
        return new Promise(resolve => {
            this.controller.dataProvider.onInitialDataLoad = (data: Data) => {
                console.log('loaded initial data');
                resolve();
            }
        });
    }

    waitForMachineViews(): Promise<any> {
        return new Promise(resolve => {
            const machineCount = this.controller.data.machines.length;
            let loadedViewCount = document.querySelectorAll('a-entity.machine').length;

            if (loadedViewCount === machineCount) {
                console.log('loaded all machine views');
                resolve();
            } else {
                $('a-scene')[0].addEventListener('child-attached', (event: CustomEvent) => {
                    if (event.detail.el.className === "machine") {
                        loadedViewCount++;
                        if (loadedViewCount === machineCount) {
                            console.log('loaded all machine views');
                            resolve();
                        }
                    }
                });
            }
        });
    }

    waitFor3DAssets(): Promise<any> {
        return new Promise(resolve => {
            this.assetRegister.onLoad(() => {
                console.log('loaded all 3D assets');
                resolve();
            });
        });
    }

    waitForImage(imageUrl: string): Promise<any> {
        return new Promise(resolve => {
            const img = new Image();
            img.onload = () => {
                console.log('image ' + imageUrl + ' loaded');
                resolve();
            };
            img.src = imageUrl;
        });
    }

    waitFor3DFonts(): Promise<any> {
        let fontPromises: Array<Promise<Response>> = [];
        const fonts = Object.values(config.fonts);
        fonts.forEach(font => {
            fontPromises.push(fetch(font));
            const fontImage = font.replace(/(-msdf)?\.[a-zA-Z]+$/, ".png")
            fontPromises.push(fetch(fontImage));
        });
        return Promise.all(fontPromises).then(() =>
            console.log("3D fonts loaded")
        );
    }

    waitForARCamera(): Promise<any> {
        return new Promise(resolve => {
            window.addEventListener('camera-init', () => {
                console.log('camera loaded');
                resolve();
            });
        });
    }

}