import { AuthenticationRequestMessage, AuthenticationResponseMessage, IMessage, InfoMessage } from "../../../../common/sync/Message";
import { MessageType } from "../../../../common/sync/MessageType";
import { BrowserSocketClient } from "./BrowserSocketClient";

export declare interface SecuredBrowserSocketClient {
    on(event: 'message', listener: (message: IMessage) => void): this;
    on(event: 'authenticated', listener: (message: AuthenticationResponseMessage) => void): this;
    on(event: 'connected', listener: () => void): this;
    on(event: 'disconnected', listener: () => void): this;
}

export class SecuredBrowserSocketClient extends BrowserSocketClient {

    protected onMessage(message: IMessage) {
        super.onMessage(message);

        if (message.type === MessageType.info) {
            const info = <InfoMessage>message;
            if (info && info.info === 'AUTHREQUIRED') {
                this.authenticate();
            }
        } else if (message.type === MessageType.authResponse) {
            const response = <AuthenticationResponseMessage>message;
            if (response && response.authenticated) {
                this.onSuccessfullAuthentication(response);
            } else {
                this.authenticate(true);
            }
        }
    }

    private onSuccessfullAuthentication(message: AuthenticationResponseMessage) {
        localStorage.setItem('fileServerPassword', message.fileServerPassword);
        this.emit('authenticated', message);
    }

    /**
     * Performs the authentication handshake.
     * @param {boolean} resetPassword if true, let the user enter the password even if already saved
    */
    authenticate(resetPassword: boolean = false) {
        let password = localStorage.getItem('password');

        if (password == undefined || resetPassword) {
            password = window.prompt("Enter server password", "");
            localStorage.setItem('password', password);
        }

        this.send(new AuthenticationRequestMessage(password));
    }
}