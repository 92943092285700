import {config} from "../../config";
import ViewType from "../components/gui/Models/ViewType";

export class URLParams {
    /**
     * Get Params
     * @returns {URLSearchParams}
     */
    static get Params(): URLSearchParams {
        return new URLSearchParams(window.location.search);
    }

    /**
     * Is Sync Enabled?
     * @returns {boolean}
     */
    static get EnableSync(): boolean {
        let param = null;

        if (this.Params.has('enableSync')) {
            param = this.Params.get('enableSync');
        } else if (this.Params.has('enabledSync')) {
            param = this.Params.get('enabledSync');
        } else if (this.Params.has('syncEnabled')) {
            param = this.Params.get('syncEnabled');
        } else if (this.Params.has('syncEnable')) {
            param = this.Params.get('syncEnable');
        }

        if (param === null) {
            return config.enableSync;
        } else {
            return param === "true";
        }
    }

    /**
     * Is Manager or Worker?
     * @returns {boolean}
     */
    static get ViewType(): ViewType {
        const param = this.Params.get('view');
        if (param === null) {
            return ViewType.Worker;
        } else {
            return param === "manager" ? ViewType.Manager : ViewType.Worker;
        }
    }

    /**
     * Should Hide Menu?
     * @returns {boolean}
     */
    static get HideMenu(): boolean {
        const param = this.Params.get('hideMenu');
        if (param === null) {
            return false;
        } else {
            return param === "true";
        }
    }

    /**
     * This is used for debugging machine views.
     * The value of this parameter represents a machine id which view is being displayed permanently.
     */
    static get PermanentMachineView(): number {
        const machineId = parseInt(this.Params.get('permanent'));
        if (isNaN(machineId)) {
            return null;
        } else {
            return machineId;
        }
    }
    
    /**
     * Is Google Glass?
     * @returns {boolean}
     */
    static get IsGoogleGlass(): boolean {
        const param = this.Params.get('googleGlass');
        if (param === null) {
            return false;
        } else {
            return param === "true";
        }
    }

    /**
     * Language.
     * @returns {string}
     */
    static get Language(): string {
        const language = this.Params.get('language');
        if (language) {
            return language;
        } else {
            return null;
        }
    }

    /**
     * Replaces the URL with the given params.
     * @param reloadPage If true, reloads the page. Otherwise just changes the URL.
     * @param view 
     * @param language 
     * @param operatorHeight 
     * @param useCase 
     */
    static update (reloadPage: boolean, view: ViewType, language: string) {
        const query = `?` + 
            `view=${view == ViewType.Worker ? 'worker' : 'manager'}&` +
            `language=${language}&` + 
            `hideMenu=${URLParams.HideMenu.toString()}&` + 
            `enableSync=${URLParams.EnableSync.toString()}&` + 
            `googleGlass=${URLParams.IsGoogleGlass.toString()}&` + 
            `permanent=${URLParams.PermanentMachineView}`;
    
        if (reloadPage) {
            window.location.href = query;
        } else {
            window.history.replaceState(null, null, query);
        }
    };


}