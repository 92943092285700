export class AframeComponent {
    /**
     * Html selector (component name)
     */
    selector: string;

    /**
     * Lifecycle of the component
     */
    lifecycle: Object;

    /**
     * @constructor
     */
    constructor(selector: string, lifecycle: Object) {
        this.selector = selector;
        this.lifecycle = lifecycle;
    }

    /**
     * Init function
     */
    init() { }

    /**
     * Attach component to DOM
     */
    attach() { }

    /**
     * Detach component from DOM
     */
    detach() { }
}