import {AssetType} from "./AssetType";
import * as $ from "jquery";

export class Asset {
    id: string;
    type: AssetType;
    src: string;
    private _onLoadPromise: Promise<any> = null;

    public constructor(id: string, type: AssetType, src: string) {
        this.id = id;
        this.type = type;
        this.src = src;
    }

    asHtml(): HTMLElement {
        let html: string;

        switch(this.type) {
            case AssetType.image:
                html = `<img crossorigin="anonymous" />`;
                break;
            case AssetType.model:
                html = `<a-asset-item />`;
                break;
        }

        const el = $(html)[0];

        this._onLoadPromise = new Promise(resolve =>
            el.addEventListener(this.loadEventName, resolve)
        );

        el.setAttribute('id', this.id);
        el.setAttribute('src', this.src);

        return el;
    }

    get loadEventName(): string {
        switch(this.type) {
            case AssetType.image:
                return 'load';
            case AssetType.model:
               return 'loaded';
        }
    }

    get onLoadPromise(): Promise<any> {
        return this._onLoadPromise;
    }

}