import {singleton} from "tsyringe";
import * as $ from 'jquery';

@singleton()
export class StatusView {

    private element: HTMLElement;

    attach() {
        this.element = $(this.htmlString)[0];
        document.body.appendChild(this.element);
    }

    get htmlString(): string {
        return `
        <div id="debug"></div>
        `;
    }

    show(text: string) {
        this.element.innerHTML = text;
        this.element.style.display = 'block';
    }

    hide() {
        this.element.style.display = 'none';
    }
}