import {config} from "../../../../config";
import {IMessage, AuthenticationRequestMessage, AuthenticationResponseMessage, InfoMessage} from "../../../../common/sync/Message";
import {MessageType} from "../../../../common/sync/MessageType";
import { EventEmitter } from "../../EventEmitter";
import { BrowserSocket } from "./BrowserSocket";

export declare interface BrowserSocketClient {
    on(event: 'message', listener: (message: IMessage) => void): this;
    on(event: 'connected', listener: () => void): this;
    on(event: 'disconnected', listener: () => void): this;
}

export class BrowserSocketClient extends EventEmitter {

    private url: string;
    private socket: BrowserSocket;

    private heartbeatInterval: number;
    private reconnectTimeout: number;

    /**
     * Creates an instance of Browser Socket Cleint.
     * @param {string} url Target socket server URL.
     * @param {boolean} enableHeartbeat If true, ping/pong messages will be used to monitor active connections. Clients must reply to ping with pong as soon as possible.
     */
    constructor(url: string, enableHeartbeat: boolean) {
        super();

        this.url = url;

        if (enableHeartbeat) {
            this.startHeartbeat();
        }
    }

    /**
     * Connect to web socket server
     */
    connect() {
        console.log(`[Sync] Attempting to connect to ${this.url}.`);

        const ws = new WebSocket(this.url);
        ws.onopen = () => this.onOpen(ws);
        ws.onclose = () => this.onClose();
    }
    
    private onOpen(ws: WebSocket) {
        console.log(`[Sync] Connected to ${this.url}.`);

        this.socket = new BrowserSocket(0, ws, config.autoDisconnectTimeout);

        this.socket.on('message', (m: IMessage) => this.onMessage(m));
        this.socket.on('close', () => this.onClose());

        this.emit('connected');
    }

    protected onMessage(message: IMessage) {
        if (message.type === MessageType.ping) {
            this.socket.pong();
        }

        this.emit('message', message);

        // override in subclasses
        
    }

    private onClose() {
        clearTimeout(this.reconnectTimeout);
        this.socket = null;
        this.emit('disconnected');

        console.log('[Sync] Reconnecting in ' + config.socketConnectionAttemptTimeout + ' seconds.');
        this.reconnectTimeout = window.setTimeout(() => 
            this.connect()
        , config.socketConnectionAttemptTimeout);
    }

    /**
     * Send the given message to the socket server.
     * @param message
     */
    send(message: IMessage) {
        if (this.socket) {
            this.socket.send(message);
        } else {
            console.error('[Sync] Cannot send a messasge. Socket server disconnected.');
        }
    }

    /**
     * Starts checking status of the socket each `config.autoDisconnectAfter` seconds.
     * Web socket default ping-pong (heartbeat algorithms) cannot be accessed from javascript.
     * The only way to detect internet connection problems is to do custom ping-pong.
     */
    private startHeartbeat() {
        this.heartbeatInterval = window.setInterval(() => 
            this.verifySocket()
        , config.autoDisconnectTimeout);
    }

    /**
     * Checks that the socket is alive. If not, terminates it.
     */
    verifySocket() {
        if (this.socket && !this.socket.isAlive) {
            this.socket.terminate();
        }
    }
}
