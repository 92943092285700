/**
 * View Type Enum
 * - worker
 * - manager
 */
enum ViewType {
    Worker,
    Manager
}

export default ViewType;
