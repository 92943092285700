import { WorkerMachineView2D, WorkerMachineView3D } from "./WorkerMachineView";
import { IMachineView } from "./MachineView";
import { Machine } from "../../../common/model/Machine";
import { Languages } from "../../languages/Languages";
import { ManagerMachineView2D, ManagerMachineView3D } from "./ManagerMachineView";
import { URLParams } from "../../services/URLParams";
import {AssetRegister} from "./Models/AssetRegister";

class MachineViewFactory {
    /**
     * Create Worker Machine View
     * @param {Machine} machine
     * @param {Languages} languages
     * @param assetRegister
     * @returns {IMachineView}
     */
    createWorkerMachineView(machine: Machine, languages: Languages, assetRegister: AssetRegister): IMachineView {
        return (URLParams.IsGoogleGlass) ? new WorkerMachineView2D(machine, languages, assetRegister) : new WorkerMachineView3D(machine, languages, assetRegister);
    }

    /**
     * Create Manager Machine View
     * @param machine
     * @param {Languages} languages
     * @param assetRegister
     * @returns {IMachineView}
     */
    createManagerMachineView(machine: Machine, languages: Languages, assetRegister: AssetRegister): IMachineView {
        return (URLParams.IsGoogleGlass) ? new ManagerMachineView2D(machine, languages, assetRegister) : new ManagerMachineView3D(machine, languages, assetRegister);
    }

    // Possible more layouts to be generated here…
}

export const machineFactory = new MachineViewFactory();
