import {AssetType} from "./AssetType";
import {Asset} from "./Asset";
import * as $ from "jquery";
import {AssetRegisterItem} from "./AssetRegisterItem";
import {singleton} from "tsyringe";

/**
 * Use Asset Register only for content that does not change dynamically.
 * Assets are never unloaded and stay in memory.
 * Load dynamic content via URL instead.
 */
@singleton()
export class AssetRegister {

    private items = new Array<AssetRegisterItem>();
    private promises = new Array<Promise<any>>();

    /**
     * Registers all assets related to one machine view. Removes obsolete assets.
     * @param assets
     * @param machineId
     */
    register(assets: Array<Asset>, machineId: number) {
        // remove old
        this.items = this.items.filter(i => i.machineId !== machineId);

        // add new
        assets.forEach(asset =>
            this.items.push(new AssetRegisterItem(asset, machineId))
        );

        this.update();
    }

    /**
     * Returns an unique list of assets based on assets from all machine views.
     */
    private uniqueAssets(): Array<Asset> {
        return this.items.reduce((result, item) => {
            const asset = item.asset;
            const notExists = result.find(r => r.id === asset.id) === undefined;
            if (notExists) { result.push(asset); }
            return result;
        }, new Array<Asset>());
    }

    /**
     * Updates scene to include all assets.
     */
    private update() {
        const assets = this.uniqueAssets();

        // add new
        assets.forEach(asset => {
            if ($('#assets > #' + asset.id).length === 0) {
                $('#assets').append(asset.asHtml());
                this.promises.push(asset.onLoadPromise);
            }
        });
    }

    /**
     * Get Items
     */
    get Items() {
        return this.items;
    }

    /**
     * The given callback is called after all assets are loaded.
     * If all assets were loaded before calling this function, it is called immediately.
     * @param callback
     */
    onLoad(callback: Function) {
        Promise.all(this.promises).then(() => callback());
    }

}