import { FollowMeWithDelayComponent } from "./FollowMeWithDelay";
import { MarkerDetectionComponent } from "./MarkerDetection";
import { StaticSceneComponent } from "./StaticScene";
import { ModelNormalizedSize } from "./ModelNormalizedSize";
import { ModelLoadingText } from "./ModelLoadingText";
import { ImageLoadingText } from "./ImageLoadingText";
import { MarkersComponent } from "./Markers";

/**
 * All Custom Components for A-FRAME
 */
export const AframeComponents = [
    FollowMeWithDelayComponent,
    MarkerDetectionComponent,
    StaticSceneComponent,
    ModelNormalizedSize,
    ModelLoadingText,
    ImageLoadingText,
    MarkersComponent
];