import { ILanguageModel } from "./ILanguageModel";
import {MachineState} from "../../common/model/MachineState";

export class LanguageModel implements ILanguageModel {
    name: string;
    locale: string;
    iconSource: string;

    probihaVyroba: string;
    porucha: string;
    vUdrzbe: string;
    vNecinnosti: string;
    neznamy: string;
    serizovani: string;
    cekaniNaKontrolu: string;

    prihlasen: string;
    prubehVyroby: string;
    vyrobenoKs: string;
    caseDoKonceCyklu: string;
    produktivitaStroje: string;
    nacitani: string;
    odpojen: string;

    constructor() { }

    getLocalizedMachineState(state: MachineState) {
        switch (state) {
            case MachineState.idle:
                return this.vNecinnosti;
            case MachineState.maintenance:
                return this.vUdrzbe;
            case MachineState.malfunction:
                return this.porucha;
            case MachineState.busy:
                return this.probihaVyroba;
            case MachineState.adjustment:
                return this.serizovani;
            case MachineState.waitingForControl:
                return this.cekaniNaKontrolu;
            default:
                return this.neznamy;
        }
    }
}
