import {ARController} from "../../ARController";
import {IDataProvider} from "./IDataProvider";
import {FakeData} from "../../../common/sync/FakeData";
import {Machine} from "../../../common/model/Machine";
import {Data} from "../../../common/sync/Data";

export class FakeDataProvider implements IDataProvider {
    controller: ARController;
    onInitialDataLoad = () => {};

    /**
     * Init function
     */
    init(controller: ARController) {
        this.controller = controller;
        this.loadFakeData();
    }

    /**
     * Loads all fake data into the controller.
     */
    private loadFakeData() {
        const fakeData = new FakeData();
        this.controller.data.updateMachines(fakeData.machines);
        this.onInitialDataLoad();
        this.controller.recreateAllViews();
    }

    /**
     * Request all initial data from the backend
     */
    requestAllData() {
        console.log(`[Sync] Requesting all data from the fake data provider.`);
        this.loadFakeData();
    }

    pause() {
        console.log('Pausing fake data sync');
    }

    start() {
        console.log('Starting fake data sync (nothing is being updated)');
    }

}
