import { EventEmitter } from "../../services/EventEmitter";
import { Button } from "./Button";
import * as $ from 'jquery';

export declare interface Switch<T> {
    on(event: 'change', listener: (value: T) => void): this;
}

export interface SwitchOption<T> {
    title: string;
    icon?: string;
    value: T;
}

export class Switch<T> extends EventEmitter {

    private title: string;
    private className: string;
    private options: Array<SwitchOption<T>>;
    private selectedValue: T;
    private element: HTMLElement;
    private optionsElement: HTMLElement;
    private buttons = new Array<Button>();

    constructor(title: string, className: string, options: Array<SwitchOption<T>>) {
        super();

        this.title = title;
        this.className = className;
        this.options = options;
    }

    attachTo(el: HTMLElement) {
        this.element = $(this.htmlString)[0];
        this.optionsElement = this.element.getElementsByTagName('div')[0];

        this.attachOptions();

        el.appendChild(this.element);
        this.update();
    }

    protected get htmlString(): string {
        return `
            <div class="${this.className}">
                ${this.title ? `<h2>${this.title}</h2>` : ''}
                <div></div>
            </div>
        `;
    }

    protected attachOptions() {
        this.options.forEach(o => {
            const button = new Button(o.title, o.icon);
            button.on('click', () => this.onSelect(o.value));
            button.attachTo(this.optionsElement);
            this.buttons.push(button);
        });
    }

    protected onSelect(value: T) {
        this.value = value;
        this.emit('change', value);
    }

    get value(): T {
        return this.selectedValue;
    }

    set value(value: T) {
        this.selectedValue = value;
        this.update();
    }

    update() {
        const selectedIndex = this.options.findIndex(o => o.value === this.selectedValue);
        this.buttons.forEach((b, i) => 
            b.isActive = i === selectedIndex
        );
    }

}