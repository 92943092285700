import moment = require("moment");

export const removeDiacritics = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

declare global {
    interface String {
        padZero(length : number) : string;
    }
}

String.prototype.padZero = function (this : string, length: number) {
    let s = this;
    while (s.length < length) {
        s = '0' + s;
    }
    return s;
};

export const dateFromMediaEfektAPIString = (str: string) => {
    return moment(str).toDate();
};

/**
 * Converts the given duration to a formatted string HH:mm:SS according to moment.js format.
 * @param milliseconds The duration to be converted.
 * @return {string} The formatted duration.
 */
export const formatDuration = (milliseconds: number): string => {
    let remaining = milliseconds / 1000; // in secs
    const remainingHours = Math.floor(remaining / 3600);
    remaining -= remainingHours * 3600;
    const remainingMinutes = Math.floor(remaining / 60);
    remaining -= remainingMinutes * 60;
    const remainingSeconds = Math.ceil(remaining);

    return (
        remainingHours.toString().padZero(2) + ":" +
        remainingMinutes.toString().padZero(2) + ":" +
        remainingSeconds.toString().padZero(2)
    );

};