
export class ProjectDto {
    /**
     * Project Name in format "Product Number_Order Number"
     */
    name: string;

    /**
     * Planned Start of Project
     */
    plannedStart: string;

    /**
     * Planned End of Project
     */
    plannedEnd: string;

    /**
     * Is this project current?
     */
    isCurrent = false;

    /**
     * Model URL
     */
    modelUrl? = "";

    /**
     * Number of produced pieces (incl. rejects)
     */
    pieces: number;

    /**
     * Number of produced pieces that have a defect
     */
    rejects: number;

    /**
     * Total number of pieces to be produced.
     */
    plannedPieces: number;

}
