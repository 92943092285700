import { IDataProvider } from "./services/dataProviders/IDataProvider";
import { Components } from "./components/Components";
import { Languages } from "./languages/Languages";
import ViewType from './components/gui/Models/ViewType';
import {container, singleton} from "tsyringe";
import FollowStrategy from "./components/gui/Models/FollowStrategy";
import {Data} from "../common/sync/Data";
import {dataProviderFactory} from "./services/dataProviders/DataProviderFactory";
import {sceneFactory} from "./scene/SceneFactory";
import { Scene } from "./scene/Scene";
import { config } from "../config";
import { LanguageModel } from "./languages/LanguageModel";
import 'aframe-gif-shader';
import 'aframe-gif-component';
import { URLParams } from "./services/URLParams";
import * as $ from 'jquery';

@singleton()
export class ARController {
    /**
     * Data with all machines of the company
     */
    data: Data = new Data();

    /**
     * Data Provider downloading data from the backend
     */
    dataProvider: IDataProvider;

    /**
     * AR Scene
     */
    scene: Scene;

    /**
     * AR Components
     */
    components: Components;

    /**
     * Languages
     */
    languages: Languages = container.resolve(Languages);

    /**
     * Current View
     */
    currentView = ViewType.Worker;

    /**
     * Current Marker
     */
    currentMarker = "";

    /**
     * Interval updating time information in views periodically.
     */
    private timeUpdateInterval: number;

    /**
     * If a machine view is set up to be displayed permanenetly without detecting the marker, its machineId is here. 
     */
    private permanentMachineViewId: number;

    constructor() {
        this.scene = sceneFactory.create(URLParams.IsGoogleGlass, config.arJsOptions);
        this.components = new Components();
        this.dataProvider = dataProviderFactory.create(URLParams.EnableSync);
    }

    /**
     * Init everything necessary for AR
     */
    init() {
        this.languages.init(this);
        this.scene.attach();
        this.components.init(this);
        this.components.attachAllComponents();
        this.dataProvider.init(this);
        this.startTimeUpdates();
    }

    /**
     * Update all machine views.
     */
    recreateAllViews() {
        this.stopTimeUpdates();

        this.components.removeAllMachineViews();

        this.components.createMachineComponentViews(this.data.machines, this.currentView);
        this.components.attachMachineViews();

        this.showPermanentViewIfRequired();

        this.startTimeUpdates();
    }

    /**
     * Re-renders one machine view.
     * @param machineId
     */
    updateView(machineId: number) {
        this.components.updateMachineView(machineId);
    }

    /**
     * Re-renders all machine views.
     */
    updateAllViews() {
        this.components.updateAllMachineViews();
    }

    /**
     * Starts updating time-based information in machine views periodically.
     */
    startTimeUpdates() {
        this.timeUpdateInterval = window.setInterval(() => this.components.updateTimeInAllViews(), 1000);
    }

    /**
     * Stops periodic updates of time-based infromation in machine views.
     */
    stopTimeUpdates() {
        window.clearInterval(this.timeUpdateInterval);
    }

    /**
     * Shows the machine view immediately regardless of the currently detected marker.
     * @param machineId The target machine's ID.
     */
    setPermanentMachineView(machineId: number) {
        this.permanentMachineViewId = machineId;
    }

    /**
     * If the permanent view is set up, this function ensures it is displayed regardless the currently detected marker.
     */
    showPermanentViewIfRequired() {
        if (this.permanentMachineViewId) {
            const view = this.components.findMachineView(this.permanentMachineViewId);
            if (view) {
                view.triggerMarkerDetection();
            } else {
                console.error(`Cannot show view for machine ${this.permanentMachineViewId}! View not found.`);
            }
        }
    }
    
    findAndSetLanguage(languageLocale: string) {
        this.languages.findAndSetLanguage(languageLocale);
    }

    setLanguage(language: LanguageModel) {
        this.languages.setLanguage(language);
    }
    
    startVR() {
        console.log('Entering VR.');
        (<any>$('#scene')[0])["enterVR"]();
    }

    changeViewType(type: ViewType) {
        if (this.currentView !== type) {
            this.currentView = type;
            this.recreateAllViews();
        }
    }

    startSync() {
        this.dataProvider.start();
    }

    stopSync() {
        this.dataProvider.pause();
    }
    
}
